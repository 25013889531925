import React from 'react'


const ImgComp = ({ src, className, alt, srcSet }) => {
    return (
        <img
            loading="lazy"
            src={src || ''}
            // srcSet={src}
            alt={alt}
            className={className} 
            // decoding='async'
           // fetchpriority='high' 
        />
    )

}

export default ImgComp