import React from 'react';
import ImgComp from '../../../ReusableComp/ImgComp';
import * as Images from '../../../../assets';
import { BgButton } from '../../../ReusableComp/Buttons';
import { useNavigate } from 'react-router-dom';

const EmptyCartContent = () => {

    const navigate = useNavigate();

    return (
        <>
            <div className="flex flex-col self-center px-5 w-full max-w-[1547px] max-md:max-w-full mx-auto py-20">
                <div className='flex flex-col items-center justify-center  '>
                    <ImgComp
                        src={Images.empty_cart_no_data}
                        alt={''}
                        className={'w-[40%] mx-auto '}
                    />
                    <div className='text-neutral-500 text-sm text-center my-5'>
                        <div>Your Cart is empty</div>
                        <div>Checkout what's trending</div>
                    </div>
                    <BgButton
                        onClick={() => navigate('/listing')}
                        name={`Discover Products`}
                    />
                </div>
            </div>
        </>
    )
}

export default EmptyCartContent 