import React from 'react';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {

    const handlePaginationClick = (pageNumber) => {
        if (pageNumber !== currentPage) {
            onPageChange(pageNumber);
        }
    };

    const handlePreviousClick = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    }; 

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    }; 
  

    return (
        <div className="flex justify-center">
            <button
                className="text-neutral-500 font-bold focus:border-red-800 focus:ring-0 focus:outline-0  focus:bg-red-800 focus:text-white px-3 py-1 border rounded-md text-center hover:bg-red-800 hover:text-white my-5 me-5 cursor-pointer text-sm"
                onClick={handlePreviousClick}
            >
              <i className='fa fa-arrow-left me-3'></i>  Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => {
                const pageNumber = index + 1;

                if (totalPages <= 6) {
                    return (
                        <div key={pageNumber} onClick={() => handlePaginationClick(pageNumber)}
                            className={`px-3 py-1 border focus:border-red-700 rounded-md  text-center hover:bg-red-800 hover:text-white my-5 me-5 cursor-pointer text-sm ${pageNumber === currentPage ? 'bg-red-800 text-white' : 'text-black'}`}
                        >
                            {pageNumber}
                        </div>
                    );
                } else {
                    if (pageNumber <= 3) {
                        return (
                            <div key={pageNumber} onClick={() => handlePaginationClick(pageNumber)}
                                className={`px-3 py-1 border focus:border-red-700  rounded-md  text-center hover:bg-red-800 hover:text-white my-5 me-5 cursor-pointer text-sm ${pageNumber === currentPage ? 'bg-red-800 text-white' : 'text-black'}`}
                            >
                                {pageNumber}
                            </div>
                        );
                    }

                    if (pageNumber > totalPages - 3 && pageNumber > currentPage + 2) {
                        return (
                            <div key={pageNumber} onClick={() => handlePaginationClick(pageNumber)}
                                className={`px-3 py-1 border focus:border-red-700  rounded-md  text-center hover:bg-red-800 hover:text-white my-5 me-5 cursor-pointer text-sm ${pageNumber === currentPage ? 'bg-red-800 text-white' : 'text-black'}`}
                            >
                                {pageNumber}
                            </div>
                        );
                    }

                    if (pageNumber === 4 && currentPage > 4) {
                        return (
                            <div key={pageNumber} className='px-3 py-1 text-black text-center my-5 me-5 text-sm'>
                                ...
                            </div>
                        );
                    }

                    if (pageNumber === totalPages - 3 && currentPage < totalPages - 3) {
                        return (
                            <div key={pageNumber} className='px-3 py-1 text-black text-center my-5 me-5 text-sm'>
                                ...
                            </div>
                        );
                    }

                    if (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2) {
                        return (
                            <div key={pageNumber} onClick={() => handlePaginationClick(pageNumber)}
                                className={`px-3 py-1 border  focus:border-red-700  rounded-md  text-center hover:bg-red-800 hover:text-white my-5 me-5 cursor-pointer text-sm ${pageNumber === currentPage ? 'bg-red-800 text-white' : 'text-black'}`}
                            >
                                {pageNumber}
                            </div>
                        );
                    }

                    return null;
                }
            })}
            <button
                className="text-neutral-500 font-bold focus:border-red-700 focus:ring-0 focus:outline-0 focus:bg-red-800 focus:text-white px-3 py-1 border rounded-md text-center hover:bg-red-800 hover:text-white my-5 me-5 cursor-pointer text-sm"
                onClick={handleNextClick}
            >
                Next <i className='fa fa-arrow-right ms-3'></i>
            </button>
        </div>
    );
};

export default Pagination;   
 