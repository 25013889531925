import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ImgComp from '../../../../ReusableComp/ImgComp';
import * as Images from '../../../../../assets';
import { fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderModalBox from './OrderModalBox';
import StarRating from '../../../../ReusableComp/StarRating';
import { SearchBar } from '../../../../ReusableComp/SearchBar';
import SortingDropdown from '../../../../ReusableComp/SortingDropdown';
import { isEmptyApiObject, isEmptyObject } from '../../../../ReusableComp/Validations';
import Pagination from '../../../../ReusableComp/Pagination';
import OrderDetailPage from './OrderDetailPage';



const MyOrderTabContent = () => {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [productId, setProductId] = useState();
  const [checkUserRatingExist, setCheckUserRatingExist] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [searchQueryData, setSearchQueryData] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [currentIndex, setCurrentIndex] = useState(1);

  const controller = new AbortController();

  const orderList = async (currentPage = 1, selectedId = "", searchQuery = '') => {

    const tokenValue = localStorage?.getItem('login api response token');

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      // return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();

      formdata.append("user_id", userId?.user_id);
      let sortByValue = "";
      if (selectedId === 1) {
        sortByValue = "asc";
      } else if (selectedId === 2) {
        sortByValue = "desc";
      }
      formdata.append("page", currentPage);
      formdata.append("sort_by", sortByValue);
      formdata.append("search", searchQuery || '');

      const result = await fetchWithFormDataToken(`api/v1/place_order_list`, formdata, storedToken, abortController);
      if (result?.status === true) {
        // toast.success(result?.message, {
        //   position: 'top-center',
        //   autoClose: 2000,
        // });
        setData(result?.data?.order_data)
      }
      else {
        // toast.error(result?.message, {
        //   position: 'top-center',
        //   autoClose: 2000,
        // });
      }
    } catch (error) {
    }
    finally {
      abortController.abort();
    }
  };

  const orderDetailList = async (id) => {

    setIsModalOpen(true)

    const tokenValue = localStorage?.getItem('login api response token');

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      // return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      formdata.append("order_id", id);


      const result = await fetchWithFormDataToken(`api/v1/place_order_details`, formdata, storedToken, abortController);
      if (result?.status === true) {
        setPreviewData(result?.data)
        setProductId(result?.data?.order_items[0]?.product_id);
        setCheckUserRatingExist(result?.data?.order_items[0]?.is_user_rate_this_item)
        setRating(result?.data?.order_items[0]?.user_item_rating?.rating)
      }
      else {
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  // const SearchBar = () => {
  //   return (
  //     <div className="flex gap-3 self-stretch px-3 py-2 my-auto text-sm leading-5 bg-white rounded-lg text-stone-400 border">
  //       <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bff447ded87998e133b50ce4e6126d8a54624089bc99dd88f323b0225c8a4ac3?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Search icon" className="shrink-0 self-start w-5 aspect-square" />
  //       <div className="flex-1">Search for anything</div>
  //     </div>
  //   );
  // }


  const RenderContent = (item) => {
    if (!item?.item) return null;

    let statusText = '';
    let statusColor = '';

    switch (item?.item?.order_status) {
      case '1':
        statusText = 'Pending';
        statusColor = 'text-orange-500';
        break;
      case '2':
        statusText = 'Order Confirm';
        statusColor = 'text-green-500';
        break;
      case '3':
        if (item?.item?.mode_of_payment === '0') {
          statusText = 'Ready For Pickup';
          statusColor = 'text-blue-600';
        } else {
          statusText = 'Ready For Shipping';
          statusColor = 'text-green-700';
        }
        break;
      case '4':
        if (item?.item?.mode_of_payment === '0') {
          statusText = 'Picked Up';
          statusColor = 'text-green-700';
        } else {
          statusText = 'Delivered';
          statusColor = 'text-green-500';
        }
        break;
      case '5':
        statusText = 'Completed';
        statusColor = 'text-green-500';
        break;
      case '6':
        statusText = 'Cancelled';
        statusColor = 'text-red-500';
        break;
      case '7':
        statusText = 'Return';
        statusColor = 'text-orange-500';
        break;
      default:
        return null;
    }

    return (
      <span className={`text-sm ${statusColor}`}>
        <i className="fa fa-circle text-xs me-2" />
        {statusText}
      </span>
    );
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);

    if (files?.length + selectedFile?.length > 3) {
      // alert("You can only select up to 3 images.");
      toast.error("You can only select up to 3 images.", {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }

    setSelectedFile(prevFiles => [...prevFiles, ...files]);

    const fileNames = files.map(file => file.name);
    setSelectedFileName(prevNames => [...prevNames, ...fileNames]);

    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(prevImages => [...prevImages, reader.result]);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
    setSelectedFile(file)

  };

  const addReviewRating = async () => {

    const tokenValue = localStorage?.getItem('login api response token');

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();

      // if (selectedFile) {
      //   formdata.append("image[]", selectedFile, selectedFileName);
      // }
      selectedFile.forEach(file => {
        formdata.append("image[]", file); // Ensure this is a File object
      });
      formdata.append("user_id", userId?.user_id);
      formdata.append("product_id", productId);
      formdata.append("order_id", previewData?.order_id);
      formdata.append("review", reviewText);
      formdata.append("rating", rating);

      const result = await fetchWithFormDataToken(`api/v1/add_review_ratings`, formdata, storedToken, abortController);

      if (result?.status === true) {
        setIsModalOpen(false);
        setRating(0);
        setReviewText('');
        setImage([]);
        setSelectedFile([]);
        setSelectedFileName([]);
        toast.success(result?.message || '', {
          position: 'top-center',
          autoClose: 2000,
        });
      }
      else {
        toast.error(result?.message, {
          position: 'top-center',
          autoClose: 2000,
        });
      }
    } catch (error) {
    }
    finally {
      abortController.abort();
    }
  };

  const handleDeleteImage = (index) => {
    setImage(prevImages => prevImages.filter((_, i) => i !== index));
    setSelectedFile(prevFiles => prevFiles.filter((_, i) => i !== index));
    setSelectedFileName(prevNames => prevNames.filter((_, i) => i !== index));
  };

  const handleClearImages = () => {
    setImage([]);
    setSelectedFile([]);
    setSelectedFileName([]);
  };


  const ProfileImage = useCallback(() => {

    return (
      <div>
        <div
          className="flex gap-1 flex-row items-center justify-center p-3 mt-2 w-full rounded-lg border border-gray-300 border-dashed terms_bg_clr max-md:px-5 max-md:max-w-full"
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <label htmlFor="file-input" className="cursor-pointer">
            <div className=' flex flex-row '>
              {image?.length > 0 && image.map((img, index) => (
                <div key={index} className="relative">
                  <img
                    key={index}
                    loading="lazy"
                    src={img}
                    alt="Selected"
                    className="self-center w-12 h-12 border-white border-solid aspect-square border-[6px] mx-auto rounded-full"
                    width=""
                    height=""
                  />
                  <button
                    type="button"
                    onClick={() => handleDeleteImage(index)}
                    className=" focus:border-0 focus:outline-0 focus:ring-0 absolute top-0 right-0 rounded-full "
                  >
                    <i className='fa fa-close bg-white text-red-500 text-xs px-0.5 rounded-lg'></i>
                  </button>
                </div>
              ))}
            </div>
            {image?.length < 3 && image?.length === 0 && (
              <div className="text-center mx-auto flex flex-row items-center ">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileSelect}
                  className="hidden"
                  id="file-input"
                  multiple
                />
                <img
                  loading="lazy"
                  src={Images.icn_add_photo}
                  alt="Selected"
                  className="self-center w-10 border-white border-solid aspect-square border-[6px] mx-auto"
                  width=""
                  height=""
                />
                <div className="text-sm font-bold">Add Photo</div>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileSelect}
              className="hidden"
              id="file-input"
              multiple
              disabled={isDisabled}
            />
          </label>
          {/* {image.length === 0 &&
            <div className='text-sm'> Add Photo </div>
          } */}
          {image.length > 0 && (
            <button
              type="button"
              onClick={handleClearImages}
              className=" focus:border-0 focus:outline-0 focus:ring-0 mt-2 bg-red-500 text-white p-1 rounded text-xs"
            >
              Clear All Images
            </button>
          )}
          {isDisabled && (
            <p>You have reached the maximum limit of 3 files.</p>
          )}
        </div>
      </div>
    );

  }, [image])

  const handleChange = useCallback((e) => {
    setReviewText(e.target.value);
  }, []);

  const handleRating = useCallback((index) => {
    setRating(index + 1);
  }, [rating]);


  const previewOrder = useMemo(() => {

    const stars = Array(5).fill(0);

    return (
      <>
        {previewData?.order_items?.length > 0 ? (
          previewData?.order_items.map((item, index) => (
            <>
              <div className='flex flex-col items-end h-full overflow-y-auto'>
                <div className='w-full'>
                  <section
                    className=" flex flex-col grow justify-center rounded-xl max-md:max-w-full"
                    key={index}
                  >
                    <div className='flex justify-between w-full max-md:flex-col' >
                      <div className='flex gap-5 w-full'>
                        <ImgComp
                          className={`w-28 h-24 border rounded-xl`}
                          src={item?.item_image ? item?.item_image : Images.logo}
                        />
                        <div className='flex justify-between w-full max-md:flex-col max-lg:gap-3'>
                          <div className='flex justify-start gap-1 items-start flex-col max-md:gap-0 max-md:w-full'>
                            <div className='mybzb_text_clr font-bold text-xs '

                            >
                              Order ID:-
                              <span>{previewData?.order_id} </span>

                            </div>
                            <div
                              className='text-lg font-medium max-md:text-base '
                            >
                              {item?.product_name}
                            </div>

                            <div className='flex justify-between items-center'>

                              <div className='flex gap-5 font-bold'>
                                <div className='text-lg md:text-base max-sm:text-sm'>
                                  ₹ {Number(item?.selling_price).toLocaleString('en-IN')}
                                </div>
                                {data?.price !== data?.selling_price &&
                                  <div className='text-lg text-stone-300 line-through'>
                                    ₹ {Number(item?.price).toLocaleString('en-IN')}
                                  </div>
                                }

                              </div>

                            </div>
                          </div>

                          <div className='gap-1 flex justify-between flex-col items-end max-md:items-start max-md:text-xs text-xs text-neutral-500 max-sm:pt-2'>
                            <div className=' text-xs text-end '>
                              {previewData?.order_date}
                            </div>
                            <div
                              className=''
                            >
                              Qty:({item?.ordered_quantity})
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </section>
                  <hr className='mt-5' />
                  <div className='text-center py-2 text-base'>How was your product?</div>
                  <hr />
                  <div className='flex flex-col items-center justify-center py-3 gap-1'>
                    <div
                      className='  text-xs text-neutral-500'
                    >
                      Your overall rating
                    </div>
                    <div className='text-center font-bold'>
                      {rating > 4 ? "Excellent" :
                        rating > 3 ? "Good" :
                          rating > 2 ? "Average" :
                            rating > 1 ? "Poor" :
                              "Very Poor"}
                    </div>
                    {checkUserRatingExist === true ?
                      <StarRating value={item?.user_item_rating?.rating} />
                      :
                      <div className="rating flex gap-2">
                        {stars.map((_, index) => (
                          <i
                            key={index}
                            className={` text-lg fa fa-star${(hover || rating) > index ? '' : '-o'} text-amber-500`}
                            onClick={() => handleRating(index)}
                          // onMouseOver={() => handleHover(index)}
                          // onMouseLeave={handleMouseLeave}
                          />
                        ))}
                      </div>
                    }


                  </div>
                  <hr />
                  <div className='flex flex-col py-3 gap-2'>
                    <div>Add detailed review</div>
                    {checkUserRatingExist === true ?

                      <div
                        className='rounded-lg text-neutral-500 border p-2 border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] '
                      >
                        {item?.user_item_rating?.comment}
                      </div>

                      :

                      <textarea
                        value={reviewText}
                        row="4"
                        className='rounded-lg text-neutral-500 border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] focus:outline-0 focus:border-0 focus:ring-slate-200'
                        placeholder='Enter here'
                        onChange={handleChange}
                      />
                    }
                  </div>
                  {checkUserRatingExist === true ?

                    <div className='py-3 gap-2 border border-gray-300 border-dashed terms_bg_clr w-full rounded-lg flex flex-row items-center justify-center'>
                      {item?.user_item_rating?.images_uploaded?.length > 0 && item?.user_item_rating?.images_uploaded.map((image, index) =>
                      (

                        <img
                          key={index}
                          src={image}
                          alt={`Image ${index + 1}`}
                          // style={{ width: '100px', height: '100px', margin: '10px' }}
                          width=""
                          height=""
                          className='rounded-full w-12 h-12'
                        />

                      ))}

                    </div>
                    :
                    <ProfileImage />

                  }


                </div>
                {checkUserRatingExist === false &&
                  <div className="flex gap-4  justify-end mt-4">
                    <button
                      type="button"
                      className=" focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-12 py-3.5 text-red-700 whitespace-nowrap rounded-lg border border-red-700 border-solid max-md:px-5"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className=" focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-8 py-3.5 text-white bg-red-700 rounded-lg max-md:px-5"
                      onClick={addReviewRating}
                    >
                      Submit
                    </button>
                  </div>

                }

              </div>


            </>
          ))

        ) : (
          <>
          </>
        )}

      </>
    )
  }, [previewData, checkUserRatingExist, rating, reviewText, handleChange, handleRating, ProfileImage, addReviewRating])

  const handleRatingClose = () => {
    setIsModalOpen(false);
    setRating(0);
    setReviewText('');
    setImage([]);
    setSelectedFile([]);
    setSelectedFileName([]);
  }

  useEffect(() => {
    orderList(currentPage, selectedId, searchQueryData);;
    return () => {
      controller.abort();
    };
  }, [currentPage, selectedId, searchQueryData]);

  const handleSearch = (searchQuery) => {
    setSearchQueryData(searchQuery)
    orderList(currentPage, selectedId, searchQuery);
  };

  const handleSelectChange = (id) => {
    setSelectedId(id);
    orderList(currentPage, id, searchQueryData)
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    orderList(pageNumber, selectedId, searchQueryData);
  };

  const handleOrderClick = (orderId) => {
    navigate(`/order-detail`,{state:{orderId:orderId}}); 
  };

  return (
    <>
      <section className="flex flex-col grow justify-center rounded-xl  max-md:max-w-full">
        <h1 className="text-3xl font-bold text-left text-zinc-800 max-md:max-w-full">
          My Orders
        </h1>
      </section>
      <>
        <div className='w-full flex justify-between items-center py-5'>
          <SearchBar onSearch={handleSearch} searchQueryData={searchQueryData} />
          <div className='flex items-center gap-5'>
            <SortingDropdown
              options={[
                { id: 1, title: 'asc' },
                { id: 2, title: 'desc' },
              ]}
              selectedId={selectedId}
              onChange={(id) => handleSelectChange(id)}
            />
          </div>
        </div>
      </>
      {!isEmptyApiObject(data) ?

        data && data?.length > 0 ?
          data.map((item, index) => (
            <section
              className=" cursor-pointer flex flex-col grow justify-center rounded-xl max-md:mt-10 max-md:max-w-full border p-5 my-3"
              key={index}
            >
              <div className='flex flex-col justify-between gap-0 '
                // onClick={() => orderDetailList(item?.id)} 
                onClick={() => {
                  handleOrderClick(item?.id)
                }}
              >
                <div className='w-full '>
                  <div className='flex gap-5 max-sm:gap-3 w-full max-sm:flex-col'>
                    <ImgComp
                      className={`w-32 h-28 border rounded-xl mx-auto`}
                      src={item?.item_image ? item?.item_image : Images.logo}
                    />
                    <div className='flex flex-row justify-between w-full max-sm:flex-col max-sm:justify-center'>
                      <div className='flex justify-start gap-1 items-start flex-col py-2'>
                        <div className='mybzb_text_clr font-bold lg:text-sm xl:text-sm 2xl:text-sm max-sm:text-xs md:text-xs'>
                          Order ID:-
                          <span className='noUnderline'>{item?.display_order_id}</span>
                        </div>
                        <div
                          className='2xl:text-xl xl:text-xl lg:text-xl font-medium max-sm:text-base md:text-xl'
                        >
                          {item?.product_name}
                        </div>
                        <div className='flex justify-between items-center'>
                          <div className='flex gap-5 items-center'>
                            <div className='2xl:text-xl xl:text-xl lg:text-xl mybzb_text_clr font-bold max-sm:text-base md:text-base'>
                              ₹ {Number(item?.order_amount).toLocaleString('en-IN')}
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className=' flex flex-col justify-start items-end text-sm text-neutral-500 py-2 max-sm:items-start max-sm:text-start max-sm:text-xs md:text-xs max-sm:py-0 md:text-end'>
                        {item?.order_date}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-end w-full rounded-md font-bold'>
                  <RenderContent item={item} />
                </div>
              </div>

            </section>
          ))
          :
          <>
            <div className='flex items-center justify-center h-full pt-20 flex-col'>
              <h1 className='text-xl mybzb_text_clr mt-3'>Order List is empty</h1>
            </div>
          </>
        :
        <>
          <div className='flex items-center justify-center h-full pt-20 flex-col'>
            <h1 className='text-xl mybzb_text_clr mt-3'>No Data is Found</h1>
          </div>
        </>
      }

      {data?.length > 0 &&
        <div className='flex w-full justify-center items-center my-5'>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      }

      {isModalOpen && (
        <OrderModalBox
          title="Add Review Rating"
          content={previewOrder}
          onClose={handleRatingClose}
          modalWidth={`max-w-lg`}
        />
      )}
      <ToastContainer />
    </>
  )
}

export default MyOrderTabContent