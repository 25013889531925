import React, { useEffect, useState } from 'react'
import ImgComp from '../../../../ReusableComp/ImgComp';
import * as Images from '../../../../../assets';
import { useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StarRating from '../../../../ReusableComp/StarRating';
import toastPromise from '../../../../../services/toastService';
import Pagination from '../../../../ReusableComp/Pagination';


const MyWishlistTabContent = () => {

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async (currentPage = 1) => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/');
      // return;
    }

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      formdata.append("page", currentPage);

      const result = await fetchWithFormDataToken(`api/v1/list_wishlist`, formdata,storedToken, abortController, null);
      if (result?.status === true) {
        setData(result?.data)
        setTotalPages(result?.data?.pagination?.total_pages)
      }
      else {
        if (isEmptyObject(result?.data) || result?.data === null) {
          setData(null);
      }
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const addCartItem = async () => {

    const tokenValue = localStorage?.getItem('login api response token');

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      // return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      formdata.append("seller_id", data?.seller_id);
      formdata.append("product_id", data?.id);


      const result = await fetchWithFormDataToken(`api/v1/product_add_to_cart`, formdata,storedToken, abortController, null);
      if (result?.status === true) {
        setTimeout(() => {
          toast.success(result?.message || '', {
            position: 'top-center',
            autoClose: 2000,
          });
          navigate('/shoppingcart')
        }, 2000);


      }
      else {
        // toast.error(result?.message, {
        //   position: 'top-center',
        //   autoClose: 2000,
        // });
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const SearchBar = () => {
    return (
      <div className="flex gap-3 self-stretch px-3 py-2 my-auto text-sm leading-5 bg-white rounded-lg text-stone-400 border">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bff447ded87998e133b50ce4e6126d8a54624089bc99dd88f323b0225c8a4ac3?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Search icon" className="shrink-0 self-start w-5 aspect-square" />
        <div className="flex-1">Search for anything</div>
      </div>
    );
  }

  const removeData = async (id) => {
    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/');
      // return;
    }

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      formdata.append("product_id", id);
      formdata.append("is_wish", "0");
      formdata.append("device_token", "");
      formdata.append("device_type", "Web");
      formdata.append("api_version", "");
      formdata.append("app_version", "");
      formdata.append("os_version", "");
      formdata.append("device_model_name", "");

       const result = await fetchWithFormDataToken(`api/v1/remove_wishlist`, formdata,storedToken, abortController, null);
       if (result?.status === true) {
          //  toast.success(result?.message || '', {
          //      position: 'top-center',
          //      autoClose: 2000,
          //  });
           fetchData();
           if (isEmptyObject(data) || data === null) {
               setData(null);
           }
       }
       else {
          //  toast.error(result?.message || '', {
          //      position: 'top-center',
          //      autoClose: 2000,
          //  });
       }
      // const response = await toastPromise(
      //   fetchWithFormDataToken('api/v1/remove_wishlist', formdata, abortController, null),
      //   {
      //     pending: "Removing Product...",
      //     success: (data) => data.message || "Product is removed",
      //     error: (data) => data.message || "Failed to remove product",
      //   }
      // );

      // if (response.status === true) {
      //   fetchData();
      //   if (isEmptyObject(data) || data === null) {
      //     setData(null);
      //   }
      // }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
};
  return (
    <>
      <section className="flex flex-col grow justify-center rounded-xl max-md:max-w-full ">
        <h1 className="text-3xl font-bold text-left text-zinc-800 max-md:max-w-full">
          My Wishlist
        </h1>
      </section>
      {isEmptyObject(data) || data === null ?

        <>
          <div className='flex items-center justify-center h-full pt-20 flex-col'>

            <i className='fa fa-shopping-bag text-lg text-neutral-500'></i>
            <h1 className='text-xl mybzb_text_clr mt-3'>Wishlist is empty</h1>
          </div>
        </>
        :
        <>
          <section className='my-5 flex justify-between items-center max-md:flex-col max-md:items-end gap-5 max-sm:gap-0'>
            <div className='w-96 max-sm:w-full'>
              <SearchBar />
            </div>
            <div className='flex gap-5 items-center max-md:gap-2 max-sm:mt-3'>
              <div className='md:text-sm'>Sort by:</div>
              <div className='border text-sm text-stone-400  px-3 py-2 rounded-xl'>
                Most Popular
                <i className='fa fa-angle-down ms-3'></i>
              </div>
            </div>
          </section>
          <section
            className="flex flex-col grow justify-center rounded-xl max-md:mt-10 max-md:max-w-full gap-5 "
          >
            {data && data?.productData?.length > 0 && data?.productData.map((item, index) => {
              return (
                <div className='flex justify-between gap-5 border rounded-lg relative' key={index}>
                  <div className='flex gap-5 p-3 pe-0 max-sm:gap-0'>
                    <ImgComp
                      className={`w-28 h-28 border rounded-xl`}
                      //src={Images.home_product_ring}
                      src={item?.product_image ? item?.product_image : Images.logo}
                    />
                    <div className=' w-full px-5 max-sm:pe-0 '>
                      <div
                        className='2xl:text-xl xl:text-xl lg:text-lg md:text-base font-medium max-sm:text-sm pt-3'
                      >
                        {item?.name}
                      </div>
                      <div className=' my-2 flex justify-between gap-0 max-sm:flex-col'>
                        {/* <div className='flex gap-5 flex-wrap w-full'> */}
                        {/* <div className='text-sm text-stone-400 flex gap-1'>
                          <div>Brand :</div>
                          <div className='text-black font-bold'>MyBzb</div>
                        </div> */}
                        {/* <div className='text-sm text-stone-400 flex gap-1'>
                          <div>Category :</div>
                          <div className='text-black font-bold'>MyBzb</div>
                        </div> */}
                        {/* <div className='text-sm text-stone-400 flex gap-1'>
                          <div>Sku :</div>
                          <div className='text-black font-bold'>MyBzb</div>
                        </div> */}
                        {/* <div className='text-sm text-stone-400 flex gap-1'>
                          <div>Weight :</div>
                          <div className='text-black font-bold'>MyBzb</div>
                        </div>
                        <div className='text-sm text-stone-400 flex gap-1'>
                          <div>Size :</div>
                          <div className=' py-1 px-2 font-bold mybzb_bg_pinkclr mybzb_text_clr text-xs rounded-xl'>16.6mm</div>
                        </div>
                      </div> */}

                        {/* <div className='text-sm text-stone-400 w-full gap-1 flex justify-end'>
                        <div>Availability :</div>
                        <div className='text-green-400 font-bold'>In Stock</div>
                      </div> */}


                        {/* </div> */}
                        <div className='flex justify-between flex-col items-center max-sm:items-start'>
                          <div className='flex gap-5 font-bold'>
                            <div className='text-xl md:text-base mybzb_text_clr max-sm:text-sm'>
                              ₹ {Number(item?.selling_price).toLocaleString('en-IN')}
                            </div>
                            {data?.price !== data?.selling_price &&
                              <div className='text-lg text-stone-300 line-through'>
                                ₹ {Number(item?.price).toLocaleString('en-IN')}
                              </div>
                            }
                            {/* <div className='text-lg text-stone-300 line-through'>
                              ₹ {Number(item?.price).toLocaleString('en-IN')}
                            </div> */}
                            {/* <div className='text-lg text-green-400 line-through bg-green-100'>
                          ₹ 20,000
                        </div> */}
                          </div>

                        </div>
                        {item?.discount > 0 && (
                          <div className=" max-sm:w-fit max-sm:text-xs justify-center self-stretch p-1 my-auto text-sm font-medium text-green-600 rounded-lg bg-green-600 bg-opacity-10">

                            {item?.discount_type === 'flat' ? (
                              <span>
                                {item?.discount < 0 ? '' : '₹'}
                                {Math.abs(item?.discount)} off
                              </span>
                            )
                              :

                              <span>

                                {Math.abs(item?.discount)}
                                {item?.discount < 0 ? '' : '%'} off
                              </span>
                            }
                          </div>
                        )}
                      </div>
                      <div className='text-sm text-neutral-400 flex items-center gap-2 max-sm:text-xs max-sm:gap-0 max-sm:flex-col max-sm:items-start max-sm:gap-2'>
                        <StarRating value={item?.rating_avg} />
                        <div>
                          ({item?.rating_avg}) reviews
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='absolute w-14 text-end right-0 pe-3 pt-1'>
                    {item?.is_favorite !== false ?
                      <i className="fa fa-heart text-red-800 text-xl cursor-pointer"
                        onClick={() => removeData(item?.product_id)}
                      ></i>
                      :
                      <i className="fa fa-heart-o text-red-800 text-xl cursor-pointer"></i>
                    }
                  </div>
                  {/* <div className='absolute w-14 text-end right-0 pe-3 pt-1'>
                    <i className="fa fa-heart text-red-800 text-xl"></i>
                  </div> */}
                  {/* <div className='flex flex-col justify-between items-center  p-3 ps-0'>
                    <div className=' w-14 text-end'>
                      <i className="fa fa-heart text-red-800 text-xl"></i>
                    </div>
                    <div className='text-white mybzb_bg_clr flex text-md w-full px-2 py-1.5 gap-2 items-center justify-center rounded-md'
                    // onClick={() => addCartItem()}
                    >
                      Add to cart
                      <i className='fa fa-shopping-cart'></i>
                    </div>
                  </div> */}
                </div>
              )
            })}


          </section>
        </>
      }
      {data?.productData?.length > 0 &&
        <div className='flex w-full justify-center items-center my-5'>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      }
      <ToastContainer />
    </>
  )
}

export default MyWishlistTabContent