import React, { useEffect, useState } from "react";
import ImgComp from "../../ReusableComp/ImgComp";
import * as Images from '../../../assets';
import { BgButton, OutlineButton } from "../../ReusableComp/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../ReusableComp/Layout";



const LocationVerification = () => {

  const navigate = useNavigate('');

  // useEffect(() => {

  //   const latitudeItem = localStorage.getItem('latitude');
  //   const longitudeItem =  localStorage.getItem('longitude');
  //   if(!latitudeItem || latitudeItem === 'undefined' || !longitudeItem || longitudeItem === 'undefined'){
  //     navigate('/')
  //   }



  // },[])



  const LocationIcon = () => {
    return (
      <>
        <ImgComp
          src={Images.logo}
          className={`w-44 mx-auto`}
        />
      </>
    );
  }

  const LocationText = () => {
    return (
      <>
        <h2 className="self-center text-3xl font-medium leading-10 text-neutral-900">
          Location
        </h2>
        <p className="mt-3 text-xl font-medium leading-8 text-neutral-900 max-lg:max-w-full">
          What is your location?
        </p>
        <p className="self-center mt-5 text-base leading-7 text-neutral-400 w-[332px]">
          We need to know your location in order to suggest nearby service
        </p>
      </>
    );
  }

  const LocationButtons = () => {

    const navigate = useNavigate();

    const [location, setLocation] = useState('');
    const [error, setError] = useState(null);
    const [address, setAddress] = useState('');

    // useEffect(() => {

    //   const latitudeItem = localStorage.getItem('latitude');
    //   const longitudeItem = localStorage.getItem('longitude');
    //   if (!latitudeItem || latitudeItem === 'undefined' || !longitudeItem || longitudeItem === 'undefined') {
    //     navigate('/location')
    //   } else {
    //     navigate('/')
    //   }


    // }, [])

    const handleClick = () => {
      navigate('/');

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setLocation(`Latitude: ${latitude}, Longitude: ${longitude}`);
            localStorage.setItem('latitude', latitude);
            localStorage.setItem('longitude', longitude);

            // navigate('/');

            // const apiKey = 'AIzaSyCqCvSlpfYVDK0k81B-jeAAus7WYmii9zM';
            // const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

            // fetch(url)
            //   .then(response => response.json())
            //   .then(data => {
            //     const addressComponents = data.results[0].address_components;
            //     const streetNumber = addressComponents.find(component => component.types.includes('street_number')).long_name;
            //     const streetName = addressComponents.find(component => component.types.includes('route')).long_name;
            //     const city = addressComponents.find(component => component.types.includes('locality')).long_name;
            //     const state = addressComponents.find(component => component.types.includes('administrative_area_level_1')).long_name;
            //     const country = addressComponents.find(component => component.types.includes('country')).long_name;

            //     setAddress(`${streetNumber} ${streetName}, ${city}, ${state} ${country}`);
            //   })
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    };


    return (
      <div
        // className="flex flex-col px-20 pb-2 mt-5 max-lg:px-5 max-lg:max-w-full"
        className="flex flex-col pb-2 mt-5 max-lg:max-w-full md:w-full md:px-0"
      >
        {/* <button
          //  className="focus:border-red-800  justify-center px-12 py-2 text-lg font-medium text-white bg-red-700 rounded lg:px-0"
          className="justify-center items-center px-20 py-4 text-lg font-medium bg-red-700 text-white rounded max-sm:px-5"
          onClick={handleClick}
        >
          {` Allow Location Access`}
        </button> */}
        {/* <BgButton name={` Allow Location Access`} onClick={handleClick} /> */}
        {/* <Link to={`/`}>
          <OutlineButton name={`Not Now`}
          //  onClick={() => navigate('/')}
          />
        </Link> */}
        <button
          onClick={handleClick}
          className="focus:outline-0 justify-center px-12 py-4 text-lg font-medium text-white bg-red-700 rounded max-lg:px-5 max-lg:mx-1">
          Allow Location Access
        </button>
        <button
          onClick={() => navigate('/')}
          className="self-center mt-4 text-sm font-semibold leading-5 text-red-700">
          Not Now
        </button>
      </div>
    );
  }

  const PrivacyLinks = () => {
    return (
      <>
        <div className="flex gap-5 justify-between mt-16 text-sm text-neutral-400 max-lg:flex-wrap max-lg:mt-10 max-lg:max-w-full">
          <a href="#">Privacy Policy</a>
          <a href="#">Terms &amp; Conditions</a>
        </div>
        <span className="text-red-800 text-sm mt-5">Powered by Headway Business Solutions LLP</span>
      </>
    );
  }

  const LeftSection = () => {
    return (
      <div className="flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full rounded-2xl">
        <div className="rounded-2xl flex flex-col grow justify-between self-stretch px-10 py-12 w-full text-center bg-white max-lg:px-5 max-lg:max-w-full">
          <LocationIcon />
          <div className="flex flex-col px-16 mt-10 max-lg:px-5 max-lg:mt-10 max-lg:max-w-full">
            <LocationText />
            <LocationButtons />
          </div>
          <PrivacyLinks />
        </div>
      </div>
    );
  }

  const RightSection = () => {
    return (
      <>
        <div className="flex flex-col ml-5 w-6/12 max-lg:ml-0 max-lg:w-full hidden sm:block">

          <ImgComp
            src={Images.verification_location}
            alt={'verification location'}
            className={'h-full w-full  rounded-r-2xl max-lg:rounded-t-none max-lg:rounded-b-2xl'}
          />
        </div>
      </>
    );
  }

  return (
    <Layout>
      <main className="flex justify-center items-center px-16 py-20 bg-white max-lg:px-5">
        <div className="max-w-full rounded-2xl shadow-2xl w-[1296px]">
          <div className="flex gap-5 max-lg:flex-col max-lg:gap-0 rounded-2xl ">
            <LeftSection />
            <RightSection />
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default LocationVerification;