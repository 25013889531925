export const validateEmail = (phoneNo) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [, domain] = phoneNo.split('@');
    return emailPattern.test(phoneNo.toLowerCase()) && domain === domain.toLowerCase();
};

export const validateName = (name) => {
    const namePattern = /^[A-Za-z\s]+$/;
    return namePattern.test(name);
};

export const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const isEmptyApiObject = (obj) => {
    return (
      !obj || // Check if it's null or undefined
      (typeof obj === 'object' && Object.keys(obj).length === 0 && obj.constructor === Object) // Check if it's an empty object
    );
  };
  
