import React from 'react'
import * as Images from '../../../assets';

const ProfileImageComponent = ({ profilePicUrl, className }) => {
    const handleImageError = (event) => {
        event.target.src = Images.logo_dummy; 
    };

    return (
        <img
            src={profilePicUrl ? profilePicUrl : Images.logo_dummy}
            alt="Profile"
            onError={handleImageError}
            className={className}
        />
    );
};

export default ProfileImageComponent  
  