import React from 'react'
import Layout from '../../ReusableComp/Layout'
import { TwoBreadcrumb } from '../../ReusableComp/Breadcrumb'
import TermsConditionContent from './TermsConditionContent'

const TermsConditions = () => {
    return (
        <>
            <Layout>
                <TwoBreadcrumb
                    href3={`/`}
                    name3={`Terms & Conditions`}
                />
                <TermsConditionContent />
            </Layout>
        </>
    )
}

export default TermsConditions