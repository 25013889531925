import React from 'react';
import Slider from 'react-slick';
import * as Images from '../../../../../assets';
import { useNavigate } from 'react-router-dom';

const MobileCategorySlider = ({ items }) => {

    const navigate = useNavigate();

    const settings = {
        dots: false,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 4500,
        cssEase: "linear",
        // cssEase: "ease-out",
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 9,
                    slidesToScroll: 9,
                    centerMode: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                    centerMode: false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                    centerMode: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 7,
                    centerMode: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    centerMode: false,
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: false,
                }
            }
        ]
    };


    const handleItemClick = (item) => {
        navigate(`/listing`, { state: { selectedCategoryValue: item?.id } });
    };

    const images = [
        `${Images.home_ts_1}`,
        `${Images.home_ts_1}`,
        `${Images.home_ts_1}`,
        `${Images.home_ts_1}`,
        `${Images.home_ts_1}`,
    ];


    return (
        <>

            <Slider {...settings}>
                {items.map((item, index) => (
                    <div key={index} className='cursor-pointer py-5 px-2'
                        onClick={() => handleItemClick(item)}
                    >
                        <div className=""
                            style={{ textAlign: '-webkit-center' }}
                        >
                            <img src={item?.categoryImage}
                                alt={item?.categoryName}
                                loading="lazy"

                                className='rounded-full'
                            />
                            <div
                                className="cursor-pointer truncate text-sm lg:text-[13px] max-lg:text-[10px] text-center mt-2 text-black max-xs:px-0 leading-none"
                            >
                                {item?.categoryName}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>

        </>
    )
}

export default MobileCategorySlider