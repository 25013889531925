import React from 'react';
import Layout from '../../../ReusableComp/Layout';
import ImgComp from '../../../ReusableComp/ImgComp';
import * as Images from '../../../../assets';
import { BgButton, width } from '../../../ReusableComp/Buttons';
import { TwoBreadcrumb } from '../../../ReusableComp/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import EmptyCartContent from '../../AddToCart/EmptyCartContent';


const ViewShoppingCart = () => {

  const navigate = useNavigate();

  const tokenValue = localStorage?.getItem('login api response token');
  const storedToken = JSON?.parse(tokenValue);

  return (
    <>
      {!storedToken ? (
        <>
          not logged in
          <div className='flex items-center justify-center  '>
            <ImgComp
              src={Images.view_shopping_cart}
              alt={''}
              className={'w-[30%] mx-auto '}
            />
          </div>
          <div className='text-center text-2xl my-5'>
            Sign in to view and manage your shopping Cart
          </div>
          <div className='mx-auto'>
            <BgButton
              name={`Sign In`}
              onClick={() => navigate('/register')}
            />
          </div>
        </>
      ) : (
        <>
          <EmptyCartContent />
        </>
      )}
    </>
  )
}

export default ViewShoppingCart