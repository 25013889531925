/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ImgComp from '../../../../../ReusableComp/ImgComp';
import * as Images from '../../../../../../assets';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../../../apiGeneralFunction';
import ProfileImageComponent from '../../../../../ReusableComp/ProfileImageComponent';
import TrimmedComponent from '../../../../../ReusableComp/TrimmedComponent';
import Layout from '../../../../../ReusableComp/Layout';
import { width } from '../../../../../ReusableComp/Buttons';
import { ThreeBreadcrumb, TwoBreadcrumb } from '../../../../../ReusableComp/Breadcrumb';
import OrderModalBox from '../OrderModalBox';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StarRating from '../../../../../ReusableComp/StarRating';


const OrderDetailPage = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const orderDetailId = location.state.orderId;
    console.log("orderDetailId", orderDetailId)

    const [data, setData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [selectedFile, setSelectedFile] = useState([]);
    const [productId, setProductId] = useState();
    const [reviewText, setReviewText] = useState('');
    const [image, setImage] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [checkUserRatingExist, setCheckUserRatingExist] = useState();
    const [errors, setErrors] = useState('');

    useEffect(() => {
        orderDetailApi();
    }, [])

    const orderDetailApi = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        if (!storedToken) {
            navigate('/');
            // return;
        }

        const user_id = localStorage?.getItem('user_data');
        const userId = JSON.parse(user_id);

        const abortController = new AbortController();

        try {

            const formdata = new FormData();
            formdata.append("user_id", userId?.user_id);
            formdata.append("order_id", orderDetailId);

            const result = await fetchWithFormDataToken(`api/v1/place_order_details`, formdata, storedToken, abortController, null);
            if (result?.status === true) {
                setData(result?.data)
                setProductId(result?.data?.order_items[0]?.product_id);
                setCheckUserRatingExist(result?.data?.order_items[0]?.is_user_rate_this_item)
                setRating(result?.data?.order_items[0]?.user_item_rating?.rating)
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    };

    console.log("place_order_details", data);

    const address = [
        data?.order_address?.flatno_building || '',
        data?.order_address?.street_area || '',
        data?.order_address?.landmark || '',
        data?.order_address?.city || '',
        data?.order_address?.state || '',
        data?.order_address?.country || '',
        data?.order_address?.pincode || ''
    ].filter(part => part.trim() !== '').join(', ');

    const pickupAddress = [
        data?.store_address?.flatno_building || '',
        data?.store_address?.street_area || '',
        data?.store_address?.landmark || '',
        data?.store_address?.city || '',
        data?.store_address?.state || '',
        data?.store_address?.country || '',
        data?.store_address?.pincode || ''
    ].filter(part => part.trim() !== '').join(', ');

    const Home = () => {
        return (
            <div className='flex items-center gap-2'>
                <ImgComp
                    src={Images.icn_home_black}
                    className='w-5'
                    alt={`home`}
                />
                <div>Home</div>
            </div>
        )
    }

    const Office = () => {
        return (
            <div className='flex items-center gap-2'>
                <ImgComp
                    src={Images.icn_office_black}
                    className='w-5'
                    alt={`office`}
                />
                <div>Office</div>
            </div>
        )
    }

    const Others = () => {
        return (
            <div className='flex items-center gap-2'>
                <ImgComp
                    src={Images.icn_location_black}
                    className='w-5'
                    alt={`others`}
                />
                <div>Others</div>
            </div>
        )
    }

    const renderContent = (title) => {
        switch (title) {
            case '1':
                return <Home />;
            case '2':
                return <Office />;
            case '3':
                return <Others />;
            default:
                return null;
        }
    }

    const handleDownloadInvoice = () => {
        const link = document.createElement('a');
        link.href = data?.invoice?.invoiceLink;
        link.setAttribute('download', 'invoice.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const Timeline = ({ deliveryStatusAchieved, modeOfPayment }) => {
        const latestStatus = parseInt(deliveryStatusAchieved) || 0;

        const shippingFlow = [
            { id: 1, name: "Pending" },
            { id: 2, name: "Confirm" },
            { id: 3, name: "Ready for Shipping" },
            { id: 4, name: "Delivered" },
        ];

        const pickupFlow = [
            { id: 1, name: "Pending" },
            { id: 2, name: "Confirm" },
            { id: 3, name: "Ready for Pickup" },
            { id: 4, name: "Picked Up" },
        ];

        const completedStep = { id: 5, name: "Completed" };

        const specialStatuses = [
            { id: 6, name: "Cancelled", color: "text-red-500" },
            { id: 7, name: "Returned", color: "text-red-500" }
        ];

        const isSpecialStatus = latestStatus >= 6;
        let activeFlow =
            modeOfPayment === "1" ? shippingFlow : pickupFlow;

        if (latestStatus === 5) {
            activeFlow = [...activeFlow, completedStep];
        }

        if (isSpecialStatus) {
            activeFlow = specialStatuses?.filter(status => status?.id === latestStatus);
        }

        return (
            <div className="timeline flex relative flex-col gap-5 h-full ">
                {activeFlow.map((step, index) => {
                    const isCompleted = step?.id <= latestStatus;
                    const isCurrent = step?.id === latestStatus;
                    const isLastStep = index === activeFlow?.length - 1;

                    const textColor =
                        isSpecialStatus
                            ? step?.color
                            : isCompleted
                                ? "text-green-600"
                                : "text-gray-500";

                    const borderColor =
                        isCompleted
                            ? "border-green-700"
                            : "border-gray-300";

                    return (
                        <>
                            {!isLastStep && (
                                <div
                                    className={`absolute left-2 z-0 w-0 ${isCompleted
                                        ? "border border-green-700 border-dashed"
                                        : "border border-dashed border-gray-300"
                                        }`}
                                    style={{
                                        top: `${index * 40 + 18}px`,
                                        height: "40px",
                                    }}
                                />
                            )}
                            <div key={step?.id} className="z-10 timeline-step flex items-start gap-5 w-full">
                                <i
                                    className={`fa fa-circle px-0.5 rounded-full text-xs ${deliveryStatusAchieved === "6" || deliveryStatusAchieved === "7"
                                        ? "border border-red-600 text-red-600"
                                        : isCompleted
                                            ? "border border-green-600 text-green-600"
                                            : "border border-gray-500 text-gray-500"
                                        }`}
                                ></i>
                                <div className={`flex flex-col ${textColor}`}>
                                    <span className="text-sm font-bold">{step?.name}</span>
                                    {/* <span className="text-xs">
                                    {isCurrent ? "In Progress" : isCompleted ? "Completed" : "Pending"}
                                </span> */}
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
        );
    };


    const cancelOrderApi = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        if (!storedToken) {
            navigate('/');
            // return;
        }

        const user_id = localStorage?.getItem('user_data');
        const userId = JSON.parse(user_id);

        const abortController = new AbortController();

        try {

            const formdata = new FormData();
            formdata.append("user_id", userId?.user_id);
            formdata.append("id", orderDetailId);

            const result = await fetchWithFormDataToken(`api/v1/place_order_cancel`, formdata, storedToken, abortController, null);
            if (result?.status === true) {
                // setData(result?.data)
                orderDetailApi();
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    };

    const returnOrderApi = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        if (!storedToken) {
            navigate('/');
            // return;
        }

        const user_id = localStorage?.getItem('user_data');
        const userId = JSON.parse(user_id);

        const abortController = new AbortController();

        try {

            const formdata = new FormData();
            formdata.append("user_id", userId?.user_id);
            formdata.append("id", orderDetailId);

            const result = await fetchWithFormDataToken(`api/v1/place_order_return`, formdata, storedToken, abortController, null);
            if (result?.status === true) {
                // setData(result?.data)
                orderDetailApi();
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    };

    const addReviewRating = async () => {

        const tokenValue = localStorage?.getItem('login api response token');

        const user_id = localStorage?.getItem('user_data');
        const userId = JSON.parse(user_id);

        const storedToken = JSON?.parse(tokenValue);
        if (!storedToken) {
            navigate('/register');
            // return;
        }

        if (!reviewText || reviewText?.trim()?.length === 0) {
            // toast.error('Please enter a review', {
            //     position: 'top-center',
            //     autoClose: 2000,
            // });
            setErrors('Please enter a review')
            return;
        }

        if (rating <= 0 || rating > 5) {
            // toast.error('Please provide a rating between 1 and 5', {
            //     position: 'top-center',
            //     autoClose: 2000,
            // });
            setErrors('Please give the rating to the product.')
            return;
        }

        const abortController = new AbortController();

        try {

            const formdata = new FormData();

            selectedFile.forEach(file => {
                formdata.append("image[]", file);
            });
            formdata.append("user_id", userId?.user_id);
            formdata.append("product_id", productId);
            formdata.append("order_id", data?.order_id);
            formdata.append("review", reviewText);
            formdata.append("rating", rating);

            const result = await fetchWithFormDataToken(`api/v1/add_review_ratings`, formdata, storedToken, abortController);

            if (result?.status === true) {
                setIsModalOpen(false);
                setRating(0);
                setReviewText('');
                setImage([]);
                setSelectedFile([]);
                setSelectedFileName([]);
                toast.success(result?.message || '', {
                    position: 'top-center',
                    autoClose: 1500,
                });
                setErrors('');
                orderDetailApi();
            }
            else {
                // toast.error(result?.message, {
                //     position: 'top-center',
                //     autoClose: 2000,
                // });
                setErrors(result?.message)
            }
        } catch (error) {
        }
        finally {
            abortController.abort();
        }
    };

    const handleRating = useCallback((index) => {
        setRating(index + 1);
    }, [rating]);

    const handleChange = useCallback((e) => {
        setReviewText(e.target.value);
    }, []);

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
    };

    const handleFileSelect = (e) => {
        const files = Array.from(e.target.files);

        if (files?.length + selectedFile?.length > 3) {
            toast.error("You can only select up to 3 images.", {
                position: 'top-center',
                autoClose: 2000,
            });
            return;
        }

        setSelectedFile(prevFiles => [...prevFiles, ...files]);

        const fileNames = files.map(file => file.name);
        setSelectedFileName(prevNames => [...prevNames, ...fileNames]);

        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(prevImages => [...prevImages, reader.result]);
            };
            reader.readAsDataURL(file);
        });
    };

    const handleFile = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(file);
        setSelectedFile(file)

    };

    const handleDeleteImage = (index) => {
        setImage(prevImages => prevImages.filter((_, i) => i !== index));
        setSelectedFile(prevFiles => prevFiles.filter((_, i) => i !== index));
        setSelectedFileName(prevNames => prevNames.filter((_, i) => i !== index));
    };

    const handleClearImages = () => {
        setImage([]);
        setSelectedFile([]);
        setSelectedFileName([]);
    };

    const handleRatingClose = () => {
        setIsModalOpen(false);
        setRating(0);
        setReviewText('');
        setImage([]);
        setSelectedFile([]);
        setSelectedFileName([]);
    }

    const ProfileImage = useCallback(() => {

        return (
            <div>
                <div
                    className="flex gap-1 flex-row items-center justify-center p-3 mt-2 w-full rounded-lg border border-gray-300 border-dashed terms_bg_clr max-md:px-5 max-md:max-w-full"
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                >
                    <label htmlFor="file-input" className="cursor-pointer">
                        <div className=' flex flex-row '>
                            {image?.length > 0 && image.map((img, index) => (
                                <div key={index} className="relative">
                                    <img
                                        key={index}
                                        loading="lazy"
                                        src={img}
                                        alt="Selected"
                                        className="self-center w-12 h-12 border-white border-solid aspect-square border-[6px] mx-auto rounded-full"
                                        width=""
                                        height=""
                                    />
                                    <button
                                        type="button"
                                        onClick={() => handleDeleteImage(index)}
                                        className=" focus:border-0 focus:outline-0 focus:ring-0 absolute top-0 right-0 rounded-full "
                                    >
                                        <i className='fa fa-close bg-white text-red-500 text-xs px-0.5 rounded-lg'></i>
                                    </button>
                                </div>
                            ))}
                        </div>
                        {image?.length < 3 && image?.length === 0 && (
                            <div className="text-center mx-auto flex flex-row items-center ">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileSelect}
                                    className="hidden"
                                    id="file-input"
                                    multiple
                                />
                                <img
                                    loading="lazy"
                                    src={Images.icn_add_photo}
                                    alt="Selected"
                                    className="self-center w-10 border-white border-solid aspect-square border-[6px] mx-auto"
                                    width=""
                                    height=""
                                />
                                <div className="text-sm font-bold">Add Photo</div>
                            </div>
                        )}
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileSelect}
                            className="hidden"
                            id="file-input"
                            multiple

                        />
                    </label>
                    {image.length > 0 && (
                        <button
                            type="button"
                            onClick={handleClearImages}
                            className=" focus:border-0 focus:outline-0 focus:ring-0 mt-2 bg-red-500 text-white p-1 rounded text-xs"
                        >
                            Clear All Images
                        </button>
                    )}

                </div>
            </div>
        );

    }, [image])

    const previewOrder = useMemo(() => {

        const stars = Array(5).fill(0);

        return (
            <>
                {data?.order_items?.length > 0 ? (
                    data?.order_items.map((item, index) => (
                        <>
                            <div className='flex flex-col items-end h-full overflow-y-auto'>
                                <div className='w-full'>
                                    <section
                                        className=" flex flex-col grow justify-center rounded-xl max-md:max-w-full"
                                        key={index}
                                    >
                                        <div className='flex justify-between w-full max-md:flex-col' >
                                            <div className='flex gap-5 w-full'>
                                                <ImgComp
                                                    className={`w-28 h-24 border rounded-xl`}
                                                    src={item?.item_image ? item?.item_image : Images.logo}
                                                />
                                                <div className='flex justify-between w-full max-md:flex-col max-lg:gap-3'>
                                                    <div className='flex justify-start gap-1 items-start flex-col max-md:gap-0 max-md:w-full'>
                                                        <div className='mybzb_text_clr font-bold text-xs '

                                                        >
                                                            Order ID:-
                                                            <span>{data?.order_id} </span>

                                                        </div>
                                                        <div
                                                            className='text-lg font-medium max-md:text-base '
                                                        >
                                                            {item?.product_name}
                                                        </div>

                                                        <div className='flex justify-between items-center'>

                                                            <div className='flex gap-5 font-bold'>
                                                                <div className='text-lg md:text-base max-sm:text-sm'>
                                                                    ₹ {Number(item?.selling_price).toLocaleString('en-IN')}
                                                                </div>
                                                                {data?.price !== data?.selling_price &&
                                                                    <div className='text-lg text-stone-300 line-through'>
                                                                        ₹ {Number(item?.price).toLocaleString('en-IN')}
                                                                    </div>
                                                                }

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className='gap-1 flex justify-between flex-col items-end max-md:items-start max-md:text-xs text-xs text-neutral-500 max-sm:pt-2'>
                                                        <div className=' text-xs text-end '>
                                                            {data?.order_date}
                                                        </div>
                                                        <div
                                                            className=''
                                                        >
                                                            Qty:({item?.ordered_quantity})
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </section>
                                    <hr className='mt-5' />
                                    <div className='text-center py-2 text-base'>How was your product?</div>
                                    <hr />
                                    <div className='flex flex-col items-center justify-center py-3 gap-1'>
                                        <div
                                            className='  text-xs text-neutral-500'
                                        >
                                            Your overall rating
                                        </div>
                                        <div className='text-center font-bold'>
                                            {rating > 4 ? "Excellent" :
                                                rating > 3 ? "Good" :
                                                    rating > 2 ? "Average" :
                                                        rating > 1 ? "Poor" :
                                                            "Very Poor"}
                                        </div>
                                        {checkUserRatingExist === true ?
                                            <StarRating value={item?.user_item_rating?.rating} />
                                            :
                                            <div className="rating flex gap-2">
                                                {stars.map((_, index) => (
                                                    <i
                                                        key={index}
                                                        className={` text-lg fa fa-star${(rating) > index ? '' : '-o'} text-amber-500`}
                                                        onClick={() => handleRating(index)}
                                                    />
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <hr />
                                    <div className='flex flex-col py-3 gap-2'>
                                        <div>Add detailed review</div>
                                        {checkUserRatingExist === true ?

                                            <div
                                                className='rounded-lg text-neutral-500 border p-2 border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] '
                                            >
                                                {item?.user_item_rating?.comment}
                                            </div>

                                            :

                                            <textarea
                                                value={reviewText}
                                                row="4"
                                                className='rounded-lg text-neutral-500 border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] focus:outline-0 focus:border-0 focus:ring-slate-200'
                                                placeholder='Enter here'
                                                onChange={handleChange}
                                            />
                                        }
                                    </div>
                                    {checkUserRatingExist === true ?

                                        <div className='py-3 gap-2 border border-gray-300 border-dashed terms_bg_clr w-full rounded-lg flex flex-row items-center justify-center'>
                                            {item?.user_item_rating?.images_uploaded?.length > 0 && item?.user_item_rating?.images_uploaded.map((image, index) =>
                                            (
                                                <img
                                                    key={index}
                                                    src={image}
                                                    alt={`Image ${index + 1}`}
                                                    width=""
                                                    height=""
                                                    className='rounded-full w-12 h-12'
                                                />
                                            ))}

                                        </div>
                                        :
                                        <ProfileImage />
                                    }

                                </div>
                                {errors &&
                                    <div className='text-xs text-red-700 py-3'>
                                        {errors}
                                    </div>
                                }
                                {checkUserRatingExist === false &&
                                    <div className="flex gap-4  justify-end mt-4">
                                        <button
                                            type="button"
                                            className=" focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-12 py-3.5 text-red-700 whitespace-nowrap rounded-lg border border-red-700 border-solid max-md:px-5"
                                            onClick={() => setIsModalOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className=" focus:border-red-700 focus:outline-0 focus:ring-0 justify-center px-8 py-3.5 text-white bg-red-700 rounded-lg max-md:px-5"
                                            onClick={addReviewRating}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                }
                            </div>
                        </>
                    ))
                ) : (
                    <>
                    </>
                )}

            </>
        )
    }, [data, checkUserRatingExist, rating, reviewText, handleChange, handleRating, ProfileImage, addReviewRating])

    return (
        <>
            <Layout>
                <ThreeBreadcrumb
                    name2={`My Orders`}
                    href2={`/profile`}
                    name3={`My Order Detail`}
                    href3={`/profile`}
                />
                <div className="flex flex-col py-20 max-md:py-10">
                    <section
                        className={`flex flex-col items-center self-center w-full max-w-${width} max-md:max-w-full  max-lg:px-5`}
                    >
                        <div
                            className="w-full flex flex-col py-6 px-6 bg-white rounded-xl border border-gray-200 border-solid shadow-[0px_4px_44px_rgba(0,0,0,0.04)] max-md:pl-5"
                        >
                            <div className='flex flex-row w-full justify-between items-start h-full '>
                                {data?.order_items?.length > 0 && data?.order_items.map((item, index) => {
                                    console.log("is_user_rate_this_item", item.is_user_rate_this_item)
                                    return (
                                        <>
                                            <div className="flex  gap-10 items-start " key={index}>
                                                <div className="flex relative flex-col">
                                                    <ProfileImageComponent
                                                        profilePicUrl={item?.item_image}
                                                        className={`w-48 h-48 p-2`}
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-2 flex-1 shrink items-start basis-0  max-md:max-w-full">
                                                    <div className="flex justify-between items-center max-w-full ">
                                                        <div className="self-stretch my-auto text-base text-red-700  text-ellipsis font-bold max-md:max-w-full">
                                                            Order ID:- <TrimmedComponent trimdata={`#${data?.order_id}`} />
                                                        </div>
                                                        <div className="self-stretch my-auto text-sm text-center text-neutral-400">

                                                        </div>
                                                    </div>
                                                    <div className="self-stretch text-lg  font-bold text-neutral-900 max-md:max-w-full">
                                                        <TrimmedComponent trimdata={item?.product_name} />
                                                    </div>
                                                    <div className="flex gap-2 items-center self-start leading-[150%]">
                                                        <div className="self-stretch text-lg font-bold "> ₹ {Number(item?.selling_price).toLocaleString('en-IN')}</div>
                                                    </div>
                                                    {(item?.is_user_rate_this_item === false && data?.delivery_status_achieved === "5") &&
                                                        <div
                                                            onClick={() => setIsModalOpen(true)}
                                                            className="cursor-pointer flex gap-2 items-center py-1 pr-3 pl-3.5  rounded-md border-amber-500 border-solid border-[1.046px]">
                                                            <div className="flex gap-1 items-start self-stretch my-auto ">
                                                                <div className="flex gap-0.5 items-start ">
                                                                    <i className="fa fa-star text-amber-500" />
                                                                </div>
                                                            </div>
                                                            <div className="self-stretch my-auto text-xs text-center text-amber-500">
                                                                Rate this product
                                                            </div>
                                                        </div>
                                                    }
                                                    {item?.user_item_rating && item?.is_user_rate_this_item === true  && data?.delivery_status_achieved === "5" && 
                                                        <StarRating value={item?.user_item_rating?.rating} />
                                                    }
                                                    <div className="flex flex-col  text-sm">
                                                        <div>
                                                            Size <span className="text-red-700 font-bold">&nbsp;
                                                                <TrimmedComponent trimdata={item?.size} />
                                                            </span>
                                                        </div>
                                                        <div className="mt-2.5">
                                                            Weight <span className="text-red-700 font-bold ">&nbsp;
                                                                <TrimmedComponent trimdata={item?.weight} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                                <Timeline
                                    deliveryStatusAchieved={data?.delivery_status_achieved}
                                    modeOfPayment={data?.mode_of_payment}
                                />
                            </div>

                            <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                                <div className="flex overflow-hidden flex-col px-4 py-3 gap-3 w-full bg-white rounded-xl border-gray-200 border-solid border-[1.223px] text-neutral-900 max-md:max-w-full">
                                    <div className="w-full text-base max-md:max-w-full font-bold">
                                        Payment Information
                                    </div>
                                    <div className=" w-full text-sm max-md:max-w-full">
                                        {data?.mode_of_payment === "0" ? "Pick Up from Store" : "Online"}
                                    </div>
                                </div>
                                <div className="flex overflow-hidden flex-col px-4 py-3 gap-3 mt-6 w-full bg-white rounded-xl border-gray-200 border-solid border-[1.223px] text-neutral-900 max-md:max-w-full">
                                    <div className="w-full text-base max-md:max-w-full font-bold">
                                        Delivery Instructions
                                    </div>
                                    <div className=" w-full text-sm leading-7 max-md:max-w-full ">
                                        <TrimmedComponent trimdata={data?.delivery_note} />
                                    </div>
                                </div>
                                <div className="flex overflow-hidden flex-col mt-6 w-full rounded-xl shadow-[0px_4px_44px_rgba(0,0,0,0.02)] max-md:max-w-full">
                                    <div className="w-full text-base text-neutral-900 max-md:max-w-full font-bold">
                                        Delivery Address
                                    </div>
                                    <div className="flex gap-4 items-center p-5 mt-3.5 w-full rounded-xl border-gray-200 border-solid border-[1.223px] max-md:max-w-full">
                                        <div className="flex flex-col flex-1 shrink self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                                            <div className="flex flex-col w-full max-md:max-w-full">
                                                {/* <div className="flex gap-2.5 items-center self-start text-lg whitespace-nowrap font-[510] text-neutral-900">
                                            <ImgComp
                                                src={Images.icn_home_black}
                                                className='w-5'
                                                alt={`home`}
                                            />
                                            <div className="self-stretch my-auto">Home</div>
                                        </div>
                                        <div className="mt-2.5 text-sm text-neutral-400 max-md:max-w-full">
                                            848 Freed Drive, Stockton, California, 95202
                                        </div> */}
                                                <div>{renderContent(data?.order_address?.address_type)}</div>
                                                <div className="mt-2.5 text-sm text-neutral-400 max-md:max-w-full">
                                                    {address}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex overflow-hidden flex-col mt-6 w-full rounded-xl shadow-[0px_4px_44px_rgba(0,0,0,0.02)] max-md:max-w-full">
                                    <div className="w-full text-base text-neutral-900 max-md:max-w-full font-bold">
                                        Pickup Address
                                    </div>
                                    <div className="flex gap-4 items-center p-5 mt-3.5 w-full rounded-xl border-gray-200 border-solid border-[1.223px] max-md:max-w-full">
                                        <div className="flex flex-col flex-1 shrink self-stretch my-auto w-full basis-0 max-md:max-w-full">
                                            <div className="flex flex-col w-full max-md:max-w-full">
                                                <div className='flex items-center gap-2'>
                                                    <ImgComp
                                                        src={Images.icn_office_black}
                                                        className='w-5'
                                                        alt={`office`}
                                                    />
                                                    <div>Office</div>
                                                </div>
                                                <div className="mt-2.5 text-sm text-neutral-400 max-md:max-w-full">
                                                    {pickupAddress}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <aside className="flex flex-col max-md:ml-0 max-md:w-full mt-5">
                                {data?.order_summary && (
                                    <section className="flex flex-col px-6 pt-5 pb-8 text-base max-sm:text-sm rounded-2xl border border-solid border-black border-opacity-10 max-md:px-5 max-md:mt-5">
                                        <header className="text-lg font-bold text-neutral-900">Order Summary</header>
                                        <div className="flex gap-5 justify-between mt-6">
                                            <p className="leading-[150%] text-neutral-400">Sub Total </p>
                                            <p className="font-semibold text-neutral-900">
                                                ₹ {Number(data?.order_summary?.Sub_total).toLocaleString('en-IN')}
                                            </p>
                                        </div>
                                        <div className="flex gap-5 justify-between mt-3">
                                            <p className="leading-[150%] text-neutral-400">Discount</p>
                                            <p className="font-semibold text-red-700">
                                                - ₹ {Number(data?.order_summary?.discount).toLocaleString('en-IN')}
                                            </p>
                                        </div>
                                        <div className="flex gap-5 justify-between mt-3">
                                            <p className="leading-[150%] text-neutral-400">Coupon Discount</p>
                                            <p className="font-semibold text-red-700">
                                                - ₹ {Number(data?.order_summary?.coupon_discount).toLocaleString('en-IN')}
                                            </p>
                                        </div>

                                        <div className="flex gap-5 justify-between mt-3 text-neutral-900">
                                            <p className="leading-[150%]">Sub total After Discount</p>
                                            <p className="font-semibold"
                                            >
                                                ₹ {Number(data?.order_summary?.Sub_total_after_discount).toLocaleString('en-IN')}
                                            </p>
                                        </div>
                                        <div className="flex gap-5 justify-between mt-3 text-neutral-900">
                                            <p className="leading-[150%]">Tax</p>
                                            <p className="font-semibold">
                                                ₹ {Number(data?.order_summary?.tax).toLocaleString('en-IN')}
                                            </p>
                                        </div>

                                        <div className="flex gap-5 justify-between mt-3">
                                            <p className="font-semibold leading-[150%] text-neutral-900">Grand total</p>
                                            <p className="font-bold text-red-700">
                                                ₹ {Number(data?.order_summary?.grand_total).toLocaleString('en-IN')}
                                            </p>
                                        </div>
                                    </section>
                                )}

                            </aside>
                            <div className="flex gap-10 justify-between items-center mt-12 w-full text-sm max-md:mt-10 max-md:max-w-full">
                                {(data?.delivery_status_achieved === "4") &&
                                    <div
                                        onClick={() => returnOrderApi()}
                                        className="cursor-pointer gap-2.5 self-stretch px-5 py-3 my-auto text-red-700 rounded-md border border-red-700 border-solid max-md:px-5"
                                    >
                                        Return
                                    </div>
                                }
                                {(data?.delivery_status_achieved === "1" || data?.delivery_status_achieved === "2" || data?.delivery_status_achieved === "3") &&
                                    <div
                                        onClick={() => cancelOrderApi()}
                                        className="cursor-pointer gap-2.5 self-stretch px-5 py-3 my-auto text-red-700 rounded-md border border-red-700 border-solid max-md:px-5">
                                        Cancel Order
                                    </div>
                                }

                                <div className="cursor-pointer gap-2.5 self-stretch px-5 py-3 my-auto text-white bg-red-700 rounded-md  max-md:px-5 max-md:max-w-full"
                                    onClick={handleDownloadInvoice}
                                >
                                    Download Invoice
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </Layout>
            {isModalOpen && (
                <OrderModalBox
                    title="Add Review Rating"
                    content={previewOrder}
                    onClose={handleRatingClose}
                    modalWidth={`max-w-lg`}
                />
            )}
        </>
    )
}

export default OrderDetailPage