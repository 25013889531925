// SearchContext.js
import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext();
export const useSearch = () => {
  return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [query, setQuery] = useState('');


  return (
    <SearchContext.Provider
      value={{ query, setQuery }}
    >
      {children}
    </SearchContext.Provider>
  );
};
