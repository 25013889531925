import React from 'react'


const TermsConditionTextsection = () => {
    return (
        <>

            <div className="flex  flex-col py-10 gap-10">
                <div className=" text-lg  max-md:max-w-full">
                    <span className="font-semibold text-red-700">
                        My Bharatiya Zaveri Bazaar (MyBzB)
                    </span>&nbsp;
                    takes pride in being managed by&nbsp;
                    <span className="font-semibold text-red-700">
                        Headway Business Solutions LLP
                    </span>
                    , a distinguished name in the world of
                    jewellery business coaching and consulting. Headway Business Solutions LLP brings a wealth of expertise and experience
                    to the helm of MyBzB, ensuring a strategic and dynamic approach to the management and growth of the platform.
                </div>

                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">1.</span>
                        <span className="font-semibold"> Acceptance of Terms:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        By accessing or using{" "}
                        <span className="font-semibold text-red-700">MyBzB</span>, you agree
                        to these Terms and Conditions. This agreement constitutes a binding
                        legal contract between you and My Bharatiya Zaveri Bazaar.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">2.</span>
                        <span className="font-semibold"> User Eligibility:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        Users must be at least 18 years old to use{" "}
                        <span className="font-semibold text-red-700">MyBzB</span>. By using
                        the website, you represent and warrant that you meet this age
                        requirement.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                    <span className="font-semibold text-red-700">3.</span>
                    <span className="font-semibold"> User Account:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        <span className="font-semibold text-red-700">A.</span> You are
                        responsible for maintaining the confidentiality of your account
                        credentials.<br></br>
                        <span className="font-semibold text-red-700">B.</span>
                        &nbsp;You agree to notify us immediately of any unauthorized use of your
                        account.<br></br>
                        <span className="font-semibold text-red-700">C.</span> We
                        reserve the right to suspend or terminate accounts at our discretion.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">4.</span>
                        <span className="font-semibold">  Intellectual Property:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        <span className="font-semibold text-red-700">A.</span> All content on{" "}
                        <span className="font-semibold text-red-700">MyBzB</span>, including
                        text, images, logos, and designs, is the property of{" "}
                        <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar
                        </span>{" "}
                        and protected by intellectual property laws.<br></br>
                        <span className="font-semibold text-red-700">B.</span> Users are
                        prohibited from reproducing, distributing, or using any content from{" "}
                        <span className="font-semibold text-red-700">MyBzB</span> without
                        explicit permission.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">5.</span>
                        <span className="font-semibold"> Product Listings:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        <span className="font-semibold text-red-700">A.&nbsp;</span> 
                        Sellers are responsible for the accuracy and legality of product listings.<br></br>
                        <span className="font-semibold text-red-700">B.&nbsp;</span>
                        <span className="font-semibold text-red-700">MyBzB</span> is not
                        responsible for the quality, safety, or legality of items listed on
                        the platform.<br></br>
                        <span className="font-semibold text-red-700">C.&nbsp;</span>
                        We reserve the right to remove or edit product listings that violate
                        our policies.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">6.</span>
                        <span className="font-semibold"> Order Processing:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        <span className="font-semibold text-red-700">A.&nbsp;</span> 
                        Buyers agree to
                        pay the full amount for items purchased through&nbsp;
                        <span className="font-semibold text-red-700">MyBzB</span>.<br></br>
                        <span className="font-semibold text-red-700">B.&nbsp;</span> 
                        Sellers agree
                        to fulfill orders promptly and accurately.<br></br>
                        <span className="font-semibold text-red-700">C.&nbsp;</span>
                        <span className="font-semibold text-red-700">MyBzB</span> 
                        is not
                        responsible for disputes between buyers and sellers but encourages
                        resolution through our dispute resolution mechanisms.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">7.</span>
                        <span className="font-semibold"> Prohibited Activities:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        <span className="font-semibold text-red-700">A.</span> Users may not
                        engage in illegal or fraudulent activities on&nbsp;
                        <span className="font-semibold text-red-700">MyBzB</span>.<br></br>
                        <span className="font-semibold text-red-700">B.</span> Prohibited
                        activities include, but are not limited to, hacking, data mining, and
                        distributing malware.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">8.</span>
                        <span className="font-semibold"> Privacy:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        <span className="font-semibold text-red-700">A.&nbsp;</span>Our Privacy
                        Policy outlines how we collect, use, and protect your personal
                        information.<br></br>
                        <span className="font-semibold text-red-700">B.&nbsp;</span>By
                        using <span className="font-semibold text-red-700">MyBzB</span>, you
                        consent to the terms outlined in the Privacy Policy.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">9.</span>
                        <span className="font-semibold"> Termination:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        We reserve the right to suspend or terminate your access to&nbsp;
                        <span className="font-semibold text-red-700">MyBzB</span> for any
                        reason, including violation of these Terms and Conditions.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">10.</span>
                        <span className="font-semibold"> Limitation of Liability:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        <span className="font-semibold text-red-700">A.&nbsp;</span>
                        <span className="font-semibold text-red-700">MyBzB</span> is provided
                        &quot;as is&quot; without any warranties.<br></br>
                        <span className="font-semibold text-red-700">B.&nbsp;</span>We are not
                        liable for any indirect, incidental, or consequential damages.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">11.</span>
                        <span className="font-semibold"> Indemnification:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        You agree to indemnify and hold&nbsp;
                        <span className="font-semibold text-red-700">
                            My Bharatiya Zaveri Bazaar
                        </span>&nbsp;
                        harmless from any claims, losses, or damages arising from your use of
                        the website.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                        <span className="font-semibold text-red-700">12.</span>
                        <span className="font-semibold"> Governing Law:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                        These Terms and Conditions are governed by the laws of [Your
                        Jurisdiction], without regard to its conflict of law principles.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                    <span className="font-semibold text-red-700">13.</span>
                    <span className="font-semibold"> Changes to Terms:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                    <span className="font-semibold text-red-700">MyBzB</span> reserves the
                    right to modify these Terms and Conditions at any time. Changes will
                    be effective immediately upon posting on the website.
                    </div>
                </div>
                <div className="flex z-0 flex-col p-6  w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="text-2xl font-medium text-neutral-900">
                    <span className="font-semibold text-red-700">14.</span>
                    <span className="font-semibold"> Contact Information:</span>
                    </div>
                    <div className="mt-3 text-base text-zinc-600 max-md:max-w-full">
                    For any questions or concerns about these Terms and Conditions, please
                    contact us at&nbsp;
                    <span className="font-semibold text-red-700">info@mybzb.com</span>
                    </div>
                </div>
                <div className="z-0  text-base text-zinc-600 max-md:mt-10 max-md:max-w-full">
                    By using&nbsp;
                    <span className="font-semibold text-red-700">
                    My Bharatiya Zaveri Bazaar
                    </span>
                    , you agree to abide by these Terms and Conditions. Thank you for
                    choosing <span className="font-bold text-red-700">MyBzB</span>, and we
                    hope you have an enjoyable experience on our platform!
                </div>
                <div className="z-0  text-lg text-neutral-900 max-md:mt-10 max-md:max-w-full">
                    <span className="font-semibold text-red-700">Last updated:</span> 29 May
                    2024
                </div>

            </div>
        </>
    )
}

export default TermsConditionTextsection