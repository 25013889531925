import { useState } from "react";

const OrderModalBox = ({ title, content, onClose, buttonContent, modalWidth }) => {

    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    return (
        <div
            style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
           // onClick={closeModal}
        //     className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full"
        className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-hidden fixed right-0 left-0  z-50 justify-center items-center w-full md:inset-0 "
      >

            <div
                // className=' bg-white rounded-2xl max-md:h-full w-full max-w-2xl zoom_in_pp_modal' 
                // className={`relative  w-full ${modalWidth}  zoom_in_pp_modal h-screen  overflow-y-auto`}
                className={`relative  w-full ${modalWidth}  zoom_in_pp_modal h-screen  overflow-y-auto flex items-center justify-center`}
                onClick={(e) => e.stopPropagation()}
                // style={{ margin: '2rem 0 !important' }}
                >
                <div 
                // className={` bg-white w-full ${modalWidth}  rounded-2xl p-6 h-full overflow-y-auto mt-16 mb-16`}
                className='my-16  bg-white overflow-y-auto p-6 rounded-2xl '
                //   style={{height:'-webkit-fill-available'}}
                >

                    <div className='flex items-center justify-center pb-5'>
                        <div className=' text-xl font-semibold w-full text-left '>
                            {title}
                        </div>
                        <div className='w-auto text-end '>
                            <button type="button" className="bg-white text-black hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-10 h-10 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={closeModal}
                                data-modal-hide="popup-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                    </div>

                    <hr/>

                    <div className='w-full flex flex-col mt-5'>

                        {content}
                    </div>


                </div>
            </div>
        </div>
    )
}

export default OrderModalBox