import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { fetchWithFormDataToken, fetchWithFormDataWithoutBody } from "../../../../../apiGeneralFunction";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ImgComp from "../../../ImgComp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Images from '../../../../../assets';
import { useSearch } from "../../../SearchContext";
import { width } from "../../../Buttons";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../../Pages/LanguageSelector";
import ProfileImageComponent from "../../../ProfileImageComponent";


const Navbar = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const [userName, setUserName] = useState([]);
    const [userPic, setUserPic] = useState([]);

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const SearchBar = ({ }) => {

        // const [query, setQuery] = useState('');
        const { query, setQuery } = useSearch();

        // const navigate = useNavigate();
        // const { handleSearch } = useContext(SearchContext);

        useEffect(() => {
            if (location.pathname !== '/listing') {
                setQuery('');
            }
        }, [location]);

        const onSearch = (e) => {
            e.preventDefault();
            if (query) {
                navigate(`/listing?search=${query}`);
            } else {
                navigate(`/listing?search=`);
            }
        };


        // const handleSearch = () => {
        //     navigate(`/listing`, { state: { searchNavbarValue: "" } });
        // };

        const handleKeyPress = (e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
                onSearch(e);
            }
        };



        return (
            <>
                <div
                    // className="w-[70%] max-md:w-full flex gap-3 self-stretch justify-between px-5 py-3.5 my-auto text-sm leading-5 bg-white rounded-lg  ms-0 border "
                    className="xl:w-[70%] 2xl:w-[70%] max-lg:w-full  flex gap-3 self-stretch justify-between px-5 py-3.5 my-auto text-sm leading-5 bg-white rounded-lg  ms-0 border "
                // onClick={() => navigate('/listing')}
                >
                    {/* <Link to={'/listing'}> */}
                    <div className="flex gap-5 items-center justify-between w-full max-md:w-full">
                        <input
                            className="text-sm p-0 border-0 w-full focus:border-0 focus:outline-0 focus:ring-0"
                            placeholder="Search for Jewellery"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyPress={(e) => {

                                handleKeyPress(e);
                            }}

                        />
                        {/* <div
                            onClick={onSearch}
                        >

                            <button className="px-3  focus:border-0 focus:outline-0 focus:ring-0">
                                <i className="fa fa-search text-stone-300"></i>
                            </button>
                        </div> */}
                        {query && (
                            <div onClick={onSearch}>
                                <button className="px-3  focus:border-0 focus:outline-0 focus:ring-0">
                                    <i className="fa fa-search text-stone-300"></i>
                                </button>
                            </div>
                        )}
                    </div>
                    {/* </Link> */}
                </div>
            </>
        );
    }


    useEffect(() => {

        const handleUserData = async () => {

            const user_id = localStorage?.getItem('user_data') || '';
            const userId = user_id ? JSON.parse(user_id) : {};

            try {
                const tokenValue = localStorage?.getItem('login api response token') || '';

                const formdata = new FormData();
                formdata.append("user_id", userId?.user_id);

                if (tokenValue) {
                    try {
                        const storedToken = JSON.parse(tokenValue);
                        if (storedToken) {
                            const result = await fetchWithFormDataToken('api/v1/get_profile', formdata, storedToken);

                            if (result?.status === true) {
                                setUserPic(result?.data?.profile_pic)
                                localStorage.setItem("get user profile", JSON.stringify(result?.data))
                                // const firstName = result?.data?.user?.name ? result?.data?.user?.name?.split(" ")[0] : '';
                                setUserName(result?.data?.firstname)
                            }
                            else {
                            }
                        }
                    } catch (error) {
                    }
                }
            } catch (error) {
            }
        };
        handleUserData();

    }, [])

    const clearCacheAndCookies = async () => {
        // Clear cache
        if (caches) {
            const cacheNames = await caches.keys();
            cacheNames.forEach(cacheName => {
                caches.delete(cacheName);
            });
        }

        // Clear cookies
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        }
    };

    const handleLogout = () => {
        const fetchAPI = async () => {
            const tokenValue = localStorage.getItem('login api response token');
            const storedToken = JSON.parse(tokenValue);

            if (!storedToken) {
                navigate('/register');
                return;
            }

            else {
                try {

                    const result = await fetchWithFormDataWithoutBody('api/v1/logout', storedToken);
                    if (result?.status === true) {
                        // toast.success(result?.message, {
                        //     position: 'top-center',
                        //     autoClose: 2000,
                        //     onClose: () => {
                        //         navigate('/');

                        //     }
                        // })
                        localStorage.clear();

                        // Clear cache and cookies
                        await clearCacheAndCookies();

                        // Navigate to login page
                        navigate('/register');
                    }
                    else {
                        toast.error(result?.message, {
                            position: 'top-center',
                            autoClose: 1000,
                        });
                    }
                } catch (error) {
                }
            }
        };
        fetchAPI();
    }


    const NavLinks = () => {
        const navLinks = [
            { label: "Products" },
            // { label: "Watch Jewellery", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a2bfaf1318e9979fb5262d581a7da93513f3e46304e29bdaeda31337c9917e99?apiKey=8e5983036f284a13866aae97819cdb73&" }, { label: "Shop by Categories", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a2bfaf1318e9979fb5262d581a7da93513f3e46304e29bdaeda31337c9917e99?apiKey=8e5983036f284a13866aae97819cdb73&" },
        ];
        return (
            <nav className="flex gap-4 justify-between self-stretch py-2 pl-3 my-auto max-md:flex-wrap">
                {navLinks.map((link, index) => (
                    <div key={index} className="flex gap-1.5 cursor-pointer hover:text-red-800  max-md:text-xs" onClick={() => navigate('/listing')}>
                        <div>{link.label}</div>
                        {/* {link.icon && (
                        <img loading="lazy" src={link.icon} alt={`${link.label} icon`} className="shrink-0 my-auto aspect-[1.08] w-[15px]" />
                    )} */}
                    </div>
                ))}
            </nav>
        );
    }

    const UserActions = () => {
        const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (
                    !event.target.closest('#user_menu')
                ) {
                    setIsUserProfileOpen(false)
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        const token = localStorage.getItem('login api response token');

        const ImgComponent = useCallback(({ userPic }) => {
            return (
                <>
                    {/* <ImgComp
                        src={userPic ? userPic : ''}
                        className={`w-10 h-10  max-sm:w-14 max-sm:h-10 rounded-full`}
                    /> */}
                    <ProfileImageComponent
                        profilePicUrl={userPic}
                        className={`w-12 h-12 rounded-full border`}
                    />
                </>
            )
        }, [userPic])
        return (
            <div className="max-md:w-full flex justify-end items-end  w-full">
                {/* {userActions.map((action, index) => (<img key={index} loading="lazy" src={action.icon} alt={action.alt} className="shrink-0 w-5 aspect-square" />))} */}
                <div className="flex gap-5 items-center my-auto me-0 text-end ">
                    <div
                        className="cursor-pointer"
                        onClick={() => navigate('/wishlist')}
                    >
                        <i className="fa fa-heart-o text-lg"></i>
                    </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => navigate('/shoppingcart')}>
                        <i className="fa fa-shopping-bag text-lg"></i>
                    </div>

                    <div className="flex flex-row justify-between gap-3 ">
                        <div className="flex self-stretch cursor-pointer items-center "
                        >
                            <Link 
                            // to={'http://3.83.101.94/#/seller'}
                            to={'https://seller.mybzb.com/#/seller'}
                            >

                                <div className="hover:text-red-800 max-md:text-xs">Become a Seller</div>

                            </Link>
                        </div>

                        {!token ?
                            <>
                                {/* <Link to={'/login'}> */}
                                <div className="flex gap-1.5 self-stretch cursor-pointer"
                                    onClick={() => navigate('/register')}
                                >
                                    <div className="hover:text-red-800 max-md:text-xs">Login / Register</div>
                                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d25d9aa6dfc583330d683b7b957fa3baeaa7b497e51aec61bad6bec8461dab48?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Login/Register icon" className="shrink-0 self-start w-5 aspect-square max-sm:w-4" />
                                </div>

                                {/* </Link> */}
                            </>
                            :
                            <div className=' flex items-center justify-end w-fit '
                            //  onMouseLeave={() => setIsUserProfileOpen(false)}
                            >
                                {/* <div className='bg-white py-2 px-3 rounded-xl '>
                                    <div className='relative'>
                                        <i className='fa fa-bell-o text-xl'></i>
                                        <span className="absolute top-1 left-2.5 transform -translate-y-1/2 w-2 h-2 bg-red-400  rounded-full"></span>
                                    </div>

                                </div> */}

                                <div
                                    // className="flex relative items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse "
                                    className='relative'
                                    onMouseEnter={() => setIsUserProfileOpen(true)}

                                >
                                    <div
                                        onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
                                        className='cursor-pointer flex items-center gap-3 bg-white px-2.5 rounded-lg py-1 '
                                    >
                                        <span className="sr-only">Open user menu</span>
                                        {userPic && /\.(png|webp|jpeg|jpg)$/.test(userPic) ? (
                                            <ImgComponent userPic={userPic} />
                                        ) : (
                                            <i className='fa fa-user-circle text-3xl mybzb_text_clr'></i>
                                        )}

                                        <div>
                                            <div className='text-sm'>
                                                {typeof userName === 'string' ? userName.split(' ')[0] : ''}
                                            </div>


                                        </div>
                                        {isUserProfileOpen ?
                                            <i className="fa fa-angle-up"></i>
                                            :
                                            <i className="fa fa-angle-down"></i>
                                        }


                                    </div>
                                    {isUserProfileOpen &&
                                        <div
                                            id="user_menu"
                                            className="absolute right-0 z-20 mt-2 w-max origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1"
                                        // className="absolute right-0 z-20 mt-2 lg:w-48 xl:w-48 2xl:w-48 md:48 max-sm:28 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1"

                                        >

                                            <ul className="py-2" aria-labelledby="user-menu-button">
                                                <Link
                                                    // to={'/profile'}
                                                    to={{ pathname: '/profile', state: { fromNavbar: true } }}
                                                >
                                                    <li>
                                                        <div
                                                            className="block px-4 py-2 text-sm text-zinc-800 hover:terms_bg_clr hover:text-red-800 cursor-pointer"

                                                        >
                                                            My Account
                                                        </div>
                                                    </li>
                                                </Link>
                                                <li>
                                                    <div
                                                        className="block px-4 pb-2 text-sm text-zinc-800 hover:terms_bg_clr hover:text-red-800 cursor-pointer"
                                                        onClick={() => handleLogout()}
                                                    >
                                                        Logout
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    }

                                </div>
                            </div>

                        }

                    </div>
                </div>

            </div >
        );
    }
    return (
        <>

            <nav
                // className={` ${isScrolled ? 'fixed' : ''} flex z-10  justify-center items-center px-16 py-4 w-full text-base bg-rose-50 shadow-2xl text-neutral-900 max-md:px-5 max-md:max-w-full `}
                // style={{
                //     position: isScrolled ? 'fixed' : 'relative',
                //     top: isScrolled ? 0 : '',
                // }}
                className="flex   justify-center items-center px-16 py-4 w-full text-base bg-rose-50 shadow-2xl text-neutral-900 max-md:px-5 max-md:max-w-full "
            >
                <div
                    className="my-auto flex lg:flex-row xl:flex-row 2xl:flex-row gap-5 justify-between lg:items-center  xl:items-center  2xl:items-center max-w-full w-[1296px] max-md:flex-wrap md:flex-col md:items-end max-sm:w-full"
                // className={`flex flex-col items-center  max-w-${width} max-md:max-w-full mx-auto max-lg:px-5`}
                >
                    {/* <div className="max-md:flex-row"> */}
                    <div className="grow gap-5 max-md:gap-3 flex flex-row md:w-full lg:w-full md:justify-between max-sm:w-full">
                        <Link to={'/'}>
                            <ImgComp
                                src={Images.logo}
                                className="shrink-0 self-stretch aspect-square max-w-[74px]"
                            />
                        </Link>
                        {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b3911bae32f07d6edb1aeab8cc0ef8377f9419cfe118a2dd3f91437cbf1745c1?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Logo" className="shrink-0 self-stretch aspect-square w-[74px]" /> */}

                        <SearchBar />

                    </div>

                    {/* <div className="flex-1 max-w-full grow ">
                            <SearchBar />
                        </div> */}
                    {/* </div> */}
                    {/* <NavLinks /> */}

                    <UserActions />

                </div>

                <ToastContainer />
            </nav>

        </>
    );
}


export default React.memo(Navbar)