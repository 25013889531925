import React, { useEffect, useState } from 'react';
import ImgComp from '../ImgComp';
import * as Images from '../../../assets';

const SortingDropdown = ({ options, selectedId, onChange }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (id) => {
        onChange(id);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const targetElement = event.target;
            if (isOpen && !targetElement.closest('#sort_menu') && !targetElement.closest('.relative')) {
                setIsOpen(false);
            }
        };
        document.body.addEventListener('click', handleOutsideClick);
        return () => {
            document.body.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen]);

    const SortIcon = ({ onClick }) => {
        return (
            <>
                <div
                    className='flex items-center gap-3 rounded-lg px-3 py-1.5 text-gray-500 max-lg:text-xs text-sm cursor-pointer'
                    onClick={onClick}
                >
                    <ImgComp
                        src={Images.sort_icon}
                        srcSet={Images.sort_icon}
                        className='w-5'
                    />
                    Sort by
                </div>
            </>
        )
    }


    return (
        <div className="relative">
            <SortIcon
                onClick={() => setIsOpen(!isOpen)}
            />
            {isOpen && (
                <div
                    id="sort_menu"
                    className="absolute left-0 top-full mt-2 w-full bg-white z-10 rounded-lg border border-gray-200">
                    {options && options.length > 0 && options.map((option) => (
                        <div
                            key={option?.id}
                            onClick={() => handleOptionClick(option?.id)}
                            className={`capitalize px-3 py-1.5 cursor-pointer text-xs ${selectedId === option?.id ? 'bg-red-800 text-white' : 'text-neutral-500 hover:bg-red-800 hover:text-white'}`}
                        >
                            {option?.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SortingDropdown;
