import React, { useEffect, useState } from 'react'
import Layout from '../../ReusableComp/Layout'
import ProductDetailContent from './ProductDetailContent'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../apiGeneralFunction';
import ShoppingCartpage from '../ShoppingCart/ShoppingCartpage';
import MyAddressTabContent from '../UserProfile/UserProfileContent/MyAddressTabContent';

const ProductDetail = () => {

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<ProductDetailContent
         />} />
      </Routes>
    </Layout>
  )
}

export default ProductDetail