import { useState } from "react";

export const SuccessMsgModalBox = ({ title, content, onClose, alertButton, modalWidth, message }) => {

    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    return (
        <div
            onClick={closeModal}
            className=" my-5 flex justify-center items-start overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  w-full md:inset-0 "

        >

            <div
                className={`bg-white rounded-2xl max-md:h-full w-full ${modalWidth} zoom_in_pp_modal`}
                onClick={(e) => e.stopPropagation()}>
                <div className={`relative bg-white w-full ${modalWidth} max-h-full rounded-2xl z-50`}>
                    <div className="relative rounded-2xl shadow-lg border border-green-500 border-b-3 border-x-0 border-t-0 bg-white px-5 py-3">

                        <div className='flex items-center justify-center'>
                            <div className='text-green-600 text-md font-semibold w-full flex items-center gap-2'>
                                <i className='fa fa-check-circle text-green-500 text-xl'></i>
                                {message}
                            </div>
                            <div className='w-auto text-end '>
                                <button type="button" className="text-gray-500 rounded-lg text-sm ms-auto inline-flex justify-center items-center"
                                    onClick={closeModal}
                                    data-modal-hide="popup-modal">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}