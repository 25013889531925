import React from 'react'


const PrivacyPolicyTextSection = () => {
    return (
        <>

            <div className="flex  flex-col py-16 ">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Information</span> We Collect:
                    </div>
                    <div className="w-full flex flex-col grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 justify-center mt-8 w-full max-md:max-w-full flex-wrap mx-auto">
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">
                                        <span className=''>Personal Information:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                        We may collect personally identifiable information, such as{" "}
                                        <span className="font-semibold text-red-700">
                                            your name, email address, phone number, and address{" "}
                                        </span>
                                        when you register as a user on MyBzB.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>

                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Payment Information:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                        When making purchases, we collect payment information, such as{" "}
                                        <span className="font-semibold text-red-700">credit card</span>{" "}
                                        details. This information is securely processed through{" "}
                                        <span className="font-semibold text-red-700">
                                            trusted payment gateways
                                        </span>
                                        , and we do not store your payment details on our servers.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col  p-6 bg-white rounded-xl border border-gray-200 border-solid basis-0  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Usage Data:</span>
                                    </div>
                                    <div className=" mt-3 w-full text-base text-zinc-600">
                                        We automatically collect information about how you interact with
                                        our website, including your IP address, browser type, pages
                                        viewed, and the duration of your visit. This data helps us{" "}
                                        <span className="font-semibold text-red-700">
                                            analyze user behavior
                                        </span>{" "}
                                        to enhance{" "}
                                        <span className="font-semibold text-red-700">our services</span>
                                        .
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col p-6 bg-white rounded-xl border border-gray-200 border-solid  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Cookies:</span>
                                    </div>
                                    <div className="flex-1 shrink gap-2.5 mt-3 w-full text-base text-zinc-600">
                                        MyBzB uses cookies to improve your browsing experience. Cookies
                                        are small files stored on your device that track your{" "}
                                        <span className="font-semibold text-red-700">preferences</span>.
                                        You can{" "}
                                        <span className="font-semibold text-red-700">disable</span>{" "}
                                        cookies through your browser settings, but this may affect the
                                        functionality of the website.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col p-6 bg-white rounded-xl border border-gray-200 border-solid  max-md:px-5 max-md:max-w-full">
                            <div className='flex  items-start gap-3'>
                                <div className=''>
                                    <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-zinc-800 text-lg">

                                        <span className=''>Website Activity:</span>
                                    </div>
                                    <div className="flex-1 shrink gap-2.5 mt-3 w-full text-base text-zinc-600">
                                        We may collect information about your interactions on our
                                        website, including the{" "}
                                        <span className="font-semibold text-red-700">products</span> you
                                        view,{" "}
                                        <span className="font-semibold text-red-700">
                                            your searches
                                        </span>
                                        , and the pages you{" "}
                                        <span className="font-semibold text-red-700">visit</span>.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold max-md:max-w-full">
                        How we use <span className="text-red-700">Your Information:</span>
                    </div>
                    <div className="flex flex-col mt-6 w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; Providing and Improving
                                Services:
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    We use your information to process transactions, provide
                                    customer support, and enhance the overall user experience on{" "}
                                    <span className="font-semibold text-red-700">MyBzB</span>.
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Communication:
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    We may use your contact information to send{" "}
                                    <span className="font-semibold text-red-700">
                                        updates, newsletters, and promotional materials
                                    </span>
                                    . You can opt-out of these communications at any time.
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">3.</span>&nbsp; Personalization:
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    Collected data is used to personalize your experience,
                                    displaying relevant content and recommendations based on your{" "}
                                    <span className="font-semibold text-red-700">
                                        preferences and browsing history
                                    </span>
                                    .
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">4.</span> Security:
                            </div>
                            <div className='flex flex-row items-center gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    Your data is safeguarded through industry-standard security
                                    measures. However, no method of transmission over the internet
                                    is{" "}
                                    <span className="font-semibold text-red-700">
                                        entirely secure
                                    </span>
                                    .
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">5.</span> Use of Demographic/Profile Data:
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1 " ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full ">
                                    We use your information to{" "}
                                    <span className="font-semibold text-red-700">
                                        assist sellers and business partners
                                    </span>
                                    , enhance customer experience, resolve disputes, promote a safe
                                    service, collect payments, measure consumer interest, inform
                                    about offers, customize experiences, detect and protect against
                                    fraud, enforce terms, and as otherwise described during
                                    information collection.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-5 flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Information </span> Sharing
                    </div>
                    <div
                        className='grid gap-5 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1'
                    >
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div >
                                <div className="text-lg font-bold text-neutral-900 ">
                                    Third-Party Service Providers
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                    We may share information with trusted third-party service
                                    providers for website functionality, transaction processing, and
                                    user behavior analysis.
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div>
                                <div className="text-lg font-bold text-neutral-900 ">
                                    Legal Requirements
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                    We may disclose information if required by law or in response to
                                    valid legal requests.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-5 flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">App </span> Permissions
                    </div>
                    <div
                        className='grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'
                    >
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div >
                                <div className="text-lg font-bold text-neutral-900 ">
                                    Internal Storage Access:
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                    To allow you to upload profile pictures, bills, and payment screenshots.
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div>
                                <div className="text-lg font-bold text-neutral-900 ">
                                    Camera Access:
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                    To enable you to take and upload profile pictures and screenshots.
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div>
                                <div className="text-lg font-bold text-neutral-900 ">
                                    Call Permission:
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                    To facilitate direct calls to sellers and customers.
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div>
                                <div className="text-lg font-bold text-neutral-900 ">
                                    Notification Permission:
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                    To send you updates, broadcast messages, and other communications from the company and sellers.
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2 px-6 py-5 w-full rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <i className="fa fa-circle-o mybzb_text_clr text-xs pt-2" ></i>
                            <div>
                                <div className="text-lg font-bold text-neutral-900 ">
                                    Location Access:
                                </div>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full mt-1">
                                    To fetch near by vendor offers and jewellery products available for customers.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Security </span>Precautions
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                        We employ stringent security measures to protect your information.
                        However, no method is foolproof, and we cannot guarantee absolute
                        security.
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700 max-md:max-w-full">
                        <span className="text-neutral-900">Advertisements on </span>Platform
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                        We use third-party advertising companies for personalized ads. You can
                        opt-out of personalized advertising through your device's settings.
                    </div>
                </div>
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Children </span>Information
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                        MyBzB does not knowingly collect or solicit personal information from
                        individuals under 18. If we become aware of such data, we take
                        immediate steps to delete it.
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Data  </span>Retention
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                        We retain personal information in compliance with applicable laws, not
                        longer than necessary for the purpose collected. Data may be retained
                        for fraud prevention, legal defense, or legitimate purposes.
                    </div>
                </div>
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Your </span>Rights
                    </div>
                    <div className="flex flex-col grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 mt-6 w-full max-md:max-w-full gap-5">
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">1.</span>&nbsp; Access and Correction
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    You have the right to access, correct, or delete your personal information. Contact us for assistance.
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col gap-3 px-6 py-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-neutral-900">
                                <span className="text-red-700">2.</span>&nbsp; Opting Out
                            </div>
                            <div className='flex flex-row items-start gap-2 '>
                                <i className="fa fa-circle-o mybzb_text_clr text-xs pt-1" ></i>
                                <div className=" w-full text-base text-zinc-600 max-md:max-w-full">
                                    You can opt-out of promotional communications by following provided instructions.
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Your </span>Consent
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                        By using our platform or providing information, you consent to the
                        collection, use, storage, and disclosure of your information in
                        accordance with this Privacy Policy.
                    </div>
                </div>
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700 max-md:max-w-full">
                        <span className="text-neutral-900">Changes to this </span>Privacy
                        Policy
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                        This policy may be updated periodically. The latest version will be
                        posted on our website.
                    </div>
                </div>
                <div className="flex  flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold text-red-700">
                        <span className="text-neutral-900">Grievance </span>Manager
                    </div>
                    <div className="mt-4 text-lg text-neutral-900 max-md:max-w-full">
                        In compliance with the Information Technology Act 2000, our Grievance
                        Officer is:
                    </div>
                    <div className="flex gap-8 items-start self-start mt-4 text-base text-zinc-600">
                        <div className="flex flex-col gap-5 grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1  gap-3">
                            <div className="flex gap-2 items-center w-full">
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div>Mr. Paresh Rajpara</div>
                            </div>
                            <div className="flex gap-2 items-center  w-full">
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div>Designation: CMD</div>
                            </div>
                            <div className="flex gap-2 items-center w-full">
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div>My Bharatiya Zaveri Bazaar</div>
                            </div>
                            <div className="flex gap-2 items-center  w-full">
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div>
                                    Email:&nbsp;
                                    <span className="font-semibold text-red-700">
                                        info@mybzb.com
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* <div className="flex flex-col gap-3">
                            <div className="flex gap-2 items-center w-full">
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div>Mr. Paresh Rajpara</div>
                            </div>
                            <div className="flex gap-2 items-center  w-full">
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div>Designation: CMD</div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="flex gap-2 items-center w-full">
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div>My Bharatiya Zaveri Bazaar</div>
                            </div>
                            <div className="flex gap-2 items-center  w-full">
                                <i className="fa fa-circle-o mybzb_text_clr text-xs" ></i>
                                <div>
                                    Email:
                                    <span className="font-semibold text-red-700">
                                        info@mybzb.com
                                    </span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="flex flex-col mt-12 w-full  max-md:mt-10 max-md:max-w-full">
                    <div className="text-3xl font-semibold">
                        <span className="text-neutral-900">Customer </span>Support
                    </div>
                    <div className="mt-4 text-lg max-md:max-w-full text-neutral-900">
                        For queries, concerns, or complaints related to this Privacy Policy,
                        please contact us at
                        <span className="font-semibold text-red-700">&nbsp; info@mybzb.com</span>.
                    </div>
                </div>
                <div className=" mt-12 text-lg text-neutral-900 max-md:mt-10 max-md:max-w-full">
                    <span className="font-semibold text-red-700">Last updated:</span> 29 May
                    2024
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicyTextSection