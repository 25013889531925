import React from "react";
import ImgComp from "../../../ReusableComp/ImgComp";
import * as Images from '../../../../assets';
import HeadingTextImageWrapper from "../../../ReusableComp/HeadingTextImageWrapper";
import { Link, useNavigate } from "react-router-dom";

const PremiumProducts = ({ width }) => {

    const navigate = useNavigate();

    const SpecialOffer = () => {
        return (
            <div className="flex flex-col w-[42%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col self-stretch my-auto text-lg font-medium max-md:max-w-full">
                    {/* <div className="font-semibold tracking-wider text-center text-red-700 uppercase max-md:max-w-full">
                        Special offer
                    </div> */}
                    <h2 className="mt-3 text-6xl text-zinc-800 max-md:max-w-full max-md:text-3xl">
                        List your <span className="mybzb_text_clr">Business </span>here
                    </h2>
                    <p className="my-4 text-zinc-600 max-md:max-w-full max-md:text-base">
                        As you browse through our gallery, we hope you'll feel the passion, energy, and dedication that drive us every day at. </p>
                    <button className="justify-center self-start px-8 py-2.5 text-base text-white bg-red-700 rounded max-md:px-5"

                    >
                        <Link to={'http://3.83.101.94/#/seller'}>
                            Become a Seller
                        </Link>
                    </button>
                </div>
            </div>
        );
    }
    const ProductImage = () => {
        return (
            <div 
            className="flex flex-col ml-5 w-[55%] max-md:ml-0 max-md:w-full  "
            style={{height:'auto'}}
            >
                <ImgComp
                    src={Images.become_seller}
                    alt={''}
                className={'w-full h-full '}
                />
                {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4e39ec4406965673b00aa3f0c8eee7a5e726b27e82b1e29ce8a54c24a2ec64ba?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Jewellery Box All in One Box" className="grow w-full rounded-2xl aspect-[1.69] max-md:mt-10 max-md:max-w-full" /> */}
            </div>);
    }

    return (
        <div 
        className="flex justify-center items-center  pt-10 pb-16 bg-white max-lg:px-5 max-sm:py-5"
        >
            <div className={`flex flex-col items-center  max-w-${width} max-md:max-w-full mx-auto `}>
                {/* <div className="text-lg font-semibold tracking-wider text-center text-red-700 uppercase">
                    Basic and Exquisite
                </div>
                <h1 className="mt-2 text-5xl font-medium capitalize text-neutral-900 max-md:max-w-full max-md:text-4xl">
                    Premium Products </h1> */}
                <HeadingTextImageWrapper
                    text="Become A Seller"
                    // className="w-auto text-center"
                    className="text-center w-auto  max-sm:w-max"
                    textClassName='px-10'
                    alignTextWrapperClassName="text-center"
                />
                <section 
                className=" justify-between self-stretch max-md:pr-0 max-md:py-0  py-8 pr-8 pl-20 mt-12 max-sm:pl-0 bg-white shadow-2xl rounded-[32px] max-md:px-5 max-md:mt-0 max-md:max-w-full max-md:py-5"
                >
                    <div className="flex gap-5 max-md:flex-col items-center justify-center max-md:p-5 ">
                        <SpecialOffer />
                        <ProductImage />
                    </div>
                </section>
            </div>
        </div>);
}

export default PremiumProducts