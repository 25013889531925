import React from 'react'
import { width } from '../../../ReusableComp/Buttons'
import TermsImgCondition from '../TermsImgCondition'
import TermsConditionTextsection from '../TermsConditionTextsection'


const TermsConditionContent = () => {
    return (
        <>

            <TermsImgCondition />
            <div className="flex flex-col py-10">
                <div className={`flex flex-col items-start self-center px-5 mt-5 w-full max-w-${width} max-md:max-w-full mx-auto`}>
                 <TermsConditionTextsection /> 
                </div>
            </div>
        </>
    )
}

export default TermsConditionContent