import React, { useEffect, useState } from 'react'
import Layout from '../../../ReusableComp/Layout'
import ShoppingCartContent from './ShoppingCartContent'
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';

const ShoppingCartpage = () => {

    return (
        <>
            <Layout>
                <ShoppingCartContent />
            </Layout>
        </>
    )
}

export default ShoppingCartpage