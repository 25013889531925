import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation, useNavigate } from "react-router-dom";
import { fetchWithFormDataToken } from "../../../../../apiGeneralFunction";
import * as Images from '../../../../../assets'
import ImgComp from "../../../../ReusableComp/ImgComp";
import { AddressModalBox } from "../../../../ReusableComp/PaymentResponseModalBox";
import { validateEmail, validateName } from "../../../../ReusableComp/Validations";
import { SuccessMsgModalBox } from "../../../../ReusableComp/ModalBox";
import toastPromise from "../../../../../services/toastService";
import ProfileImageComponent from "../../../../ReusableComp/ProfileImageComponent";
import TrimmedComponent from "../../../../ReusableComp/TrimmedComponent";

const AccountTabContent = ({ }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [image, setImage] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [readOnly, setReadOnly] = useState(true);
  const [clearedInputs, setClearedInputs] = useState({});
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [apiErrorResponse, setApiErrorResponse] = useState('');
  const [successMsg, setSuccessMsg] = useState(false);

  const [userFormData, setUserFormData] = useState({
    name: '',
    email: '',
  });

  const constructFullName = (first, middle, last) => {
    return [first, middle, last].filter(Boolean).join(' ').trim();
  };

  useEffect(() => {
    const fullName = constructFullName(firstName, middleName, lastName);
    if (userFormData.name !== fullName) {
      setUserFormData(prevFormData => ({
        ...prevFormData,
        name: fullName,
      }));
    }
  }, [firstName, middleName, lastName]);


  const handleFormChange = (e) => {

    e.preventDefault();

    const { name, email, value } = e.target;

    if (name === 'name') {
      const words = value.split(" ");
      setUserFormData(prevFormData => ({
        ...prevFormData,
        name: value,
      }));
    } else if (name === 'email') {
      if (value === '') {
        setUserFormData(prevFormData => ({
          ...prevFormData,
          email: '',
        }));
      } else {
        setUserFormData(prevFormData => ({
          ...prevFormData,
          email: value,
        }));
      }
    }
  };

  const fullName = `${firstName} ${middleName} ${lastName}`;

  const handleUserData = async () => {

    const user_id = localStorage?.getItem('user_data') || '';
    const userId = user_id ? JSON.parse(user_id) : {};

    try {
      const tokenValue = localStorage?.getItem('login api response token') || '';

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);

      if (tokenValue) {
        try {
          const storedToken = JSON.parse(tokenValue);
          if (storedToken) {
            const result = await fetchWithFormDataToken('api/v1/get_profile', formdata, storedToken);
            // c
            if (result?.status === true) {
              setUserData(result?.data)
              setFirstName(result?.data?.firstname)
              setMiddleName(result?.data?.middlename)
              setLastName(result?.data?.lastname)
              //  setEmail(result?.data?.email)
              setUserFormData(prevFormData => ({
                ...prevFormData,
                email: result.data.email,
              }));
            }
            else {
            }
          }
        } catch (error) {
        }
      }else{
        navigate('/register');
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    handleUserData();
  }, [])

  const handleFormSubmit = () => {

    const fetchAPI = async () => {
      const tokenValue = localStorage?.getItem('login api response token');
      const storedToken = JSON?.parse(tokenValue);

      const id = localStorage?.getItem('user_data');
      const user_data = JSON?.parse(id);

      try {
        const formdata = new FormData();
        if (selectedFile) {

          formdata.append("profile_pic", selectedFile, selectedFileName);
        } else if (userFormData?.profile_pic) {

          formdata.append("profile_pic", userData?.profile_pic);
        }
        formdata.append("first_name", userFormData?.name);
        formdata.append("middlename", '');
        formdata.append("last_name", '');
        formdata.append("email", userFormData?.email || userData?.email);
        formdata.append("user_id", user_data?.user_id);

        const result = await fetchWithFormDataToken('api/v1/update_profile', formdata, storedToken);

        if (result?.status === true) {
          setShow(!show)
          handleUserData();
        }
        else {
        }

        // const response = await toastPromise(
        //   fetchWithFormDataToken('api/v1/update_profile', formdata, storedToken),
        //   {
        //     pending: "Updating Profile...",
        //     success: (data) => data.message || "Profile is updated",
        //     error: (data) => data.message || "Failed to Update profile",
        //   }
        // );
        // if (response.status === true) {
        //   setShow(!show)
        //   handleUserData();
        // }
      } catch (error) {
      }

    };

    if (!userFormData?.name && !userFormData.email) {
      toast.error('Please enter your full name and email address', {
        position: 'top-center',
        autoClose: 3000,
      });
      return
      // setSuccessMsg(true)
      // setApiErrorResponse('Please enter your full name and email address')
      // setTimeout(() => {
      //   setSuccessMsg(false)
      // }, 2000)

    }
    else if (!userFormData?.name) {
      toast.error('Please enter your full name', {
        position: 'top-center',
        autoClose: 3000,
      });
      return
      // setSuccessMsg(true)
      // setApiErrorResponse('Please enter your full name')
      // setTimeout(() => {
      //   setSuccessMsg(false)
      // }, 2000)
    }
    else if (!validateName(userFormData?.name)) {
      toast.error('Name should not contain numbers or special characters.', {
        position: 'top-center',
        autoClose: 3000,
      });
      return
      // setSuccessMsg(true)
      // setApiErrorResponse('Name should not contain numbers or special characters.')
      // setTimeout(() => {
      //   setSuccessMsg(false)
      // }, 2000)
    }
    else if (!userFormData?.email) {
      toast.error('Please enter your email address', {
        position: 'top-center',
        autoClose: 3000,
      });
      return
    }

    else if (!validateEmail(userFormData?.email)) {
      toast.error('Please enter a valid email address.', {
        position: 'top-center',
        autoClose: 3000,
      });
      return
    }
    else {
      fetchAPI();
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state?.openEditProfileTab) {
      setShow(true)
    }

  }, [location]);

  const phone = localStorage.getItem('register api response');
  const storedPhone = JSON.parse(phone);

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    handleFile(file);
    const fileName = file;
    setSelectedFileName(fileName?.name)
  };


  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
    setSelectedFile(file)
  };

  const ProfileImage = () => {

    return (
      <div
        className="flex flex-col p-6 mt-8 w-48  rounded-lg border border-red-700 border-dashed terms_bg_clr max-md:px-5 max-md:max-w-full"
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <label htmlFor="file-input" className="cursor-pointer">
          {image ? (
            <div className="text-center  mx-auto">
               <ProfileImageComponent
                profilePicUrl={image}
                className={`self-center w-28 h-28 border-white border-solid aspect-square border-[6px] mx-auto`}
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
                className="hidden"
                id="file-input"
              />

            </div>
          ) : (
            <div className="text-center  mx-auto">
              <ProfileImageComponent
                profilePicUrl={userData?.profile_pic}
                className={`w-28 h-28 mx-auto`}
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
                className="hidden"
                id="file-input"
              />
            </div>
          )}
          <div className="w-full text-end">
            <i className="fa fa-edit text-red-700"></i>
          </div>
          {!image &&
            <>


              <div className="mt-1 text-xs text-center text-neutral-400 max-md:max-w-full">
                SVG, PNG, JPG or GIF (max. 800x400px)
              </div>
            </>
          }
        </label>
      </div>
    );

  }

  const addressData = userData?.firstname + " " + userData?.middlename + " " + userData?.lastname

  function cleanUpCommas(input = '') {
    return input
      .replace(/\s*,\s*/g, ',')
      .replace(/,{2,}/g, ',')
      .replace(/(^,|,$)/g, '')
      .replace(/,(?!\s)/g, ', ')
  }

  return (
    <>
      <main className="flex flex-col justify-center rounded-xl max-w-full w-[1296px]">
        <h1 className="w-full text-3xl font-bold text-left text-zinc-800 max-md:max-w-full ">
          {show ? `Edit Profile` : ` Account Information`}
        </h1>

        {show ?
          <ProfileImage />
          :
          <div
            className="pr-6 mt-8 bg-white rounded-xl border border-gray-200 border-solid max-lg:p-5"
          >

            <div
              className="flex flex-row max-lg:flex-col gap-5 text-center items-center"
            >
              <div
                className="flex  border-e-2 border-gray-200 border-solid max-lg:border-0 max-lg:w-full w-[30%] justify-center  max-lg:border-0"
              >

                {userData?.profile_pic && /\.(png|webp|jpeg|jpg)$/.test(userData?.profile_pic) ? (
                  <>
                    <ProfileImageComponent
                      profilePicUrl={userData?.profile_pic}
                      className={`w-48 h-48 rounded-full p-2`}
                    />
                  </>
                ) : (
                  <>
                    <ProfileImageComponent
                      profilePicUrl={Images.logo}
                      className={`w-48 h-48 p-2`}
                    />

                  </>
                )}
              </div>
              <div
                className="flex flex-row w-full max-lg:w-full gap-5 max-lg:flex-col max-lg:gap-0 max-lg:mt-5  grid lg:grid-cols-2 xl:justify-between lg:justify-center items-center"
              >
                <div
                  className="my-auto flex-1 grow w-full text-left w-full max-lg:text-center"
                >
                  <div
                    className="text-xl font-medium text-zinc-800 h-5 "
                  >
                    {userData ? userData?.firstname + " " + userData?.middlename + " " + userData?.lastname : 'Full Name'}
                  </div>
                  <div
                    className="mt-3.5 text-base text-neutral-400 max-md:max-w-full flex-wrap text-wrap "
                    style={{
                      overflowWrap: 'break-word'
                    }}
                  >
                    <span className="noUnderline">{userData?.email}</span>
                  </div>
                </div>
                <div className="max-lg:mt-3">
                  <button
                    onClick={() => setShow(true)}
                    className="justify-center px-4 py-2.5 text-white mybzb_bg_clr rounded-lg focus:outline-0 w-48 ">
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        }

        <section className="flex flex-col justify-center p-5 mt-6 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:max-w-full">
          <h2 className="text-2xl font-medium text-left text-zinc-800 max-md:max-w-full">Personal Information</h2>
          <div className="flex gap-5 justify-between mt-8 max-md:flex-wrap grid lg:grid-cols-2 grid-cols-1">

            <div className="flex flex-col max-md:max-w-full">
              <div className="text-sm text-neutral-400 max-md:max-w-full">
                Full Name
                <span className="mybzb_text_clr">*</span>
              </div>
              {show ?
                <input
                  type="text"
                  name="name"
                  value={userFormData?.name}
                  onChange={handleFormChange}
                  className="focus:border-gray-200 focus:ring-0 focus:outline-0 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
                />
                :
                <>
                  <div
                    className="focus:border-gray-200 focus:ring-0 focus:outline-0 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
                  >
                    {cleanUpCommas(addressData)}
                  </div>
                </>
              }
            </div>
            <div className="flex flex-col max-md:max-w-full ">
              <div className="text-sm text-neutral-400 max-md:max-w-full">
                Email Address
                <span className="mybzb_text_clr">*</span>
              </div>
              {show ?
                <input
                  type="email"
                  name="email"
                  value={userFormData?.email || email}
                  onChange={handleFormChange}
                  className="focus:border-gray-200 focus:ring-0 focus:outline-0 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
                />
                :
                <>
                  <div
                    className="noUnderline focus:border-gray-200 focus:ring-0 focus:outline-0 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
                  >
                      <TrimmedComponent trimdata={userData?.email} />
                    {/* {userData?.email} */}
                  </div>
                </>
              }
            </div>

            <div className="noUnderline flex flex-col max-md:max-w-full">
              <div className="text-sm text-neutral-400 max-md:max-w-full">Phone Number</div>
              <div className=" h-12 px-3.5 py-3 mt-1.5 text-base bg-gray-100 rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full">
                {storedPhone}
              </div>
            </div>
          </div>
        </section>
        {show &&
          <div className="flex gap-5 pl-20 mt-6 text-base justify-end font-medium max-md:flex-wrap max-md:pl-5">
            <button
              onClick={handleFormSubmit}
              className="justify-center px-4 py-2.5 text-white mybzb_bg_clr rounded-lg focus:outline-0">
              Save Changes
            </button>
          </div>
        }
      </main>

      {successMsg &&
        <SuccessMsgModalBox
          message={apiErrorResponse}
          modalWidth={`max-w-sm`}
          onClose={() => setSuccessMsg(false)}
        />
      }
    </>
  );
}

export default AccountTabContent