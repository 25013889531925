/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { postFormData } from "../../../apiGeneralFunction";
import ImgComp from "../../ReusableComp/ImgComp";
import * as Images from '../../../assets';
import Layout from "../../ReusableComp/Layout";


const OptimizeVerification = () => {

    const numberOfDigits = 4;
    const navigate = useNavigate();

    const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
    const otpRef = useRef(new Array(numberOfDigits).fill(""));
    const otpBoxReference = useRef([]);
    const [timer, setTimer] = useState(60);
    const [showPassword, setShowPassword] = useState(false);

    const register_number = localStorage?.getItem("register api response");
    const user_phone_no = JSON?.parse(register_number);

    const handleSubmit = async () => {

        const otp = otpRef.current.join("");

        if (otp === '') {
            toast.error('Please enter the  OTP', {
                position: 'top-center',
                autoClose: 1500,
            });
            return;
        }

        const register_number = localStorage?.getItem("register api response");
        const user_phone_no = JSON?.parse(register_number);


        const formdata = new FormData();
        formdata.append("country_code", "+91");
        formdata.append("phone", user_phone_no);
        formdata.append("verification_code", otp);
        formdata.append("device_token", "");
        formdata.append("device_type", "Web");
        formdata.append("api_version", "");
        formdata.append("app_version", "");
        formdata.append("os_version", "");
        formdata.append("device_model_name", "");
        formdata.append("app_language", "");

        try {
            const responsePromise = postFormData('api/v1/verifyotp', formdata);

            const response = await toast.promise(
                responsePromise,
                {
                    pending: {
                        render() {
                            return "Sending OTP...";
                        },
                        position: 'top-center',
                        autoClose: false,
                    },
                    success: {
                        render({ data }) {
                            if (data.status === false) {
                                throw new Error(data.message);
                            }
                            return data.message || "OTP sent successfully!";
                        },
                        position: 'top-center',
                        autoClose: 1000,
                    },
                    error: {
                        render({ data }) {
                            return data?.message || "Failed to send OTP.";
                        },
                        position: 'top-center',
                        autoClose: 1500,
                    }
                }
            );

            if (response.status === true) {
                localStorage.setItem("login api response token", JSON.stringify(response?.data?.token))
                localStorage.setItem("user_data", JSON.stringify(response?.data))

                setTimeout(() => {
                    navigate('/location');
                }, 3000);
            }

        } catch (error) {

            toast.error("An unexpected error occurred. Please try again.", {
                position: 'top-center',
                autoClose: 3000,
            });
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleChange = useCallback((value, index) => {
        otpRef.current[index] = value;

        if (value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }, [numberOfDigits]);

    const handleBackspaceAndEnter = useCallback((e, index) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        }
        if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }, [numberOfDigits]);

    const handleKeyPress = useCallback((e) => {
        if (e?.key === 'Enter') {
            handleSubmit();
        }
    }, [handleSubmit]);

    const OTPVerificationImage = useCallback(() => (
        <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/33182bb4a34d51254c94257e2b3e52809ccb4863687eb854d868e5b9fc8a3488?apiKey=8e5983036f284a13866aae97819cdb73&"
            alt="OTP Verification"
            className="max-w-full aspect-[0.99] w-[114px]"
        />
    ), [Images]);

    const PolicyLinks = () => (
        <>
            <div className="flex gap-5 justify-between self-stretch mt-9 text-sm text-center text-neutral-400 max-lg:flex-wrap max-lg:max-w-full">
                <a href="#">Privacy Policy</a>
                <a href="#">Terms &amp; Conditions</a>
            </div>
            <span className="text-red-800 text-sm mt-5">Powered by Headway Business Solutions LLP</span>
        </>
    );

    const ImageSection = useCallback(() => (
        <>

            <ImgComp
                src={Images.verification_otp}
                srcSet={Images.verification_otp}
                alt={'verification otp'}
                className={'h-full w-full rounded-r-2xl max-lg:rounded-t-none max-lg:rounded-b-2xl'}
            />

        </>
    ), [Images]);

    return (
        <Layout>
            <div className="flex justify-center items-center px-16 py-20 bg-white max-lg:px-5">
                <div className="max-w-full rounded-2xl shadow-2xl w-[1296px]">
                    <div className="flex gap-5 max-lg:flex-col max-lg:gap-0 ">
                        <div className="flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full  rounded-2xl">

                            <div className="flex flex-col grow justify-between rounded-2xl items-center self-stretch px-10 py-12 w-full bg-white max-lg:px-5 max-lg:max-w-full">
                                <OTPVerificationImage />
                                <h1 className="mt-9 text-3xl leading-10 text-center ">
                                    OTP <span className="text-red-700 font-bold">Verification</span>
                                </h1>
                                <p className="mt-3 text-base leading-6 text-center text-neutral-400 max-lg:max-w-full">
                                    The confirmation code was sent on {user_phone_no}
                                </p>

                                <div className="flex gap-5 self-stretch px-20 mt-5 max-lg:flex-wrap max-lg:px-5 items-center justify-center">
                                    <div className='flex items-center gap-4'>
                                        {otpRef.current.map((digit, index) => (
                                            <input
                                                type="text"
                                                pattern="^[0-9]"
                                                key={index}
                                                defaultValue={digit}
                                                maxLength={1}
                                                onChange={(e) => handleChange(e.target.value, index)}
                                                onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                                inputMode="numeric"
                                                onKeyPress={(e) => {
                                                    const charCode = e.which || e.keyCode;
                                                    if (charCode < 48 || charCode > 57) {
                                                        e.preventDefault();
                                                    }
                                                    handleKeyPress(e);
                                                }}
                                                ref={(reference) => (otpBoxReference.current[index] = reference)}
                                                className={`border w-16 h-16 max-sm:w-12 max-sm:h-12 text-center text-black p-3 rounded-md block bg-white focus:border-2 focus:outline-none appearance-none border border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] focus:border-gray-200 focus:ring-0`}
                                                autoFocus={index === 0}
                                            />
                                        ))}
                                    </div>
                                </div>

                                <p className="mt-5 text-base leading-6 text-neutral-900">
                                    <span className="font-medium text-neutral-400">Code expires in: </span>{" "}
                                    <span className="font-medium text-neutral-900">{timer} seconds</span>
                                </p>

                                <button

                                    onClick={handleSubmit}
                                    className=" justify-center items-center px-16 py-4 mt-5 max-w-full text-lg font-medium text-center text-white whitespace-nowrap bg-red-700 rounded max-lg:px-5 max-lg:py-2">
                                    Verify
                                </button>
                                <p className="mt-5 text-base leading-6 text-red-700">
                                    <span className="font-medium text-neutral-400">Dont get the code? </span>{" "}
                                    <button

                                        onClick={handleSubmit}
                                        className="font-medium text-red-700 ">
                                        Resend
                                    </button>
                                </p>
                                <PolicyLinks />
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-6/12 max-lg:ml-0 max-lg:w-full  hidden sm:block">
                            <ImageSection />
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </Layout>
    );
}

export default OptimizeVerification;